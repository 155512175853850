import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthService from "../../Api_Module/Api_Services/AuthService";
import LoaderHelper from "../../UtilityComponent/Loading/LoaderHelper";
import { alertErrorMessage } from "../../UtilityComponent/CustomAlertMessage";

const ReferralList = () => {
  const [donwlineRef, setDonwlineRef] = useState([]);
  const { user, level } = useParams();
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    handleDownlineCount(user, level);
  }, [user]);

  const handleDownlineCount = async (userId, Level) => {
    try {
      if (+Level > 10) return;
      LoaderHelper.loaderStatus(true);
      const result = await AuthService.downline(userId, +Level);
      if (result?.success) setDonwlineRef(result?.data);
    } catch (error) { alertErrorMessage(error.message) };
  };

  return (
    <>
      <section className="inner-page-banner">
        <div className="container">
          <div className="inner text-center">
            <h1 className="title text-gradient">
              Referral List
            </h1>
            <nav className="mt-4">
              <ol className="breadcrumb justify-content-center">
                <li className="breadcrumb-item">
                  <Link to="/ReferralPage">Referral Program</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Referral List
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <section className="">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-8" >
              <div className="d-flex align-items-center" >
                <th className="cursor-pointer d-flex align-items-center mb-3 " onClick={() => navigate(-1)}><a href="#"><i className="ri-arrow-left-line me-2"></i>Back</a></th>
              </div>
              <div className="table-responsive">
                <table className="table ">
                  <thead >
                    <tr>
                      <th className="text-center" >Level</th>
                      <th className="text-center"  >User Id</th>
                    </tr>
                  </thead>
                  <tbody>
                    {donwlineRef?.length > 0 ? (
                      donwlineRef.map((item) => (
                        <tr className="cursor-pointer" key={item?.userId} onClick={() => navigate(`/ReferralList/${item?.userId}/${+item?.level + 1}`)}>
                          <td className="text-center"  >{item?.level}</td>
                          <td className="text-center"  >{item?.userId}</td>
                        </tr>
                      ))
                    ) : (
                      <tr rowSpan="5">
                        <td colSpan="12">
                          <p className="text-center" style={{ textAlign: "center" }}>
                            No data Available
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReferralList;
