import React, { useState, useEffect } from "react";
import moment from "moment";
import AuthService from "../../Api_Module/Api_Services/AuthService";
import { alertErrorMessage, alertWarningMessage } from "../../UtilityComponent/CustomAlertMessage";
import LoaderHelper from "../../UtilityComponent/Loading/LoaderHelper";
import { useOutletContext } from "react-router-dom";
import DataTableBase from "../../UtilityComponent/DataTable";

const ActivitylogPage = () => {

  const [activity, setActivity] = useState([]);
  const [skipCount, setSkipCount] = useState(0);
  const [setActiveTab] = useOutletContext()
  let limitCount = 10

  useEffect(() => {
    let URL = window.location.href?.split('/');
    let route = URL.pop();
    setActiveTab(route)
    activityLogs(0, 10);
  }, []);

  const activityLogs = async (skip, limit) => {
    LoaderHelper.loaderStatus(true)
    await AuthService.activityLogs(skip, limit).then(async result => {
      if (result?.success) {
        if (result?.data?.length > 0) {
          setSkipCount(skip);
          setActivity(result?.data);
          return;
        } else if (skip !== 0) {
          alertWarningMessage('No more data found')
        }
      } else {
        alertErrorMessage(result?.message);
      }
    });
  };

  const Column = [
    { name: <>Sr No.</>, selector: (row, index) => skipCount + index + 1 },
    { name: <>Date/Time</>, wrap: true, selector: row => moment(row?.createdAt).format('MMMM Do YYYY, h:mm:ss a'), },
    { name: <>Page Visited</>, wrap: true, selector: row => row.page, },
    { name: <>IP Address</>, wrap: true, selector: row => row.ipAddress, },
  ];

  return (
    <>
      <div className="tab-pane" id="ActivityPill" role="tabpanel" aria-labelledby="Activity-pill">
        <div className="upload-formate mb-6 d-flex justify-content-center align-items-center">
          <div>
            <h3 className="mb-1 text-center">
              Activity Logs
            </h3>
            <p className="formate mb-0">
              Your  Activity Logs display for all Activity
            </p>
          </div>
        </div>
        {activity.length === 0 ?
          <div className="favouriteData">
            <img src="/images/no-data.svg" className="img-fluid" width="96" height="96" alt="" />
            <br />
            <p className="mt-3 mb-4" > No Data Found. </p>
          </div>
          :
          <div className="row">
            <div className="col-md-12 m-auto">
              <div className="form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded">
                <div className="activity-wrapper">
                  <div className="custom-history">
                    <div className="table-responsive">
                      <DataTableBase columns={Column} data={activity} />
                    </div>
                    {activity.length !== 0 &&
                      <div className="container pt-3 pb-4  table_control" >
                        <div className=" row align-items-center justify-content-end " >
                          <div className="btn-group btn-group-mini justify-content-end " role="group" aria-label="Basic radio toggle button group">
                            <input type="button" className="btn-check" name="btnradio" id="btnradio250" autoComplete="off" disabled={skipCount <= 1} />
                            <label className="btn " htmlFor="btnradio250" onClick={() => { activityLogs(skipCount - 10, limitCount) }}>Previous</label>

                            <input type="button" className="btn-check" name="btnradio" id="btnradio375" autoComplete="off" disabled={activity.length < limitCount} />
                            <label className="btn " htmlFor="btnradio375" onClick={() => { activityLogs(skipCount + 10, limitCount) }}>Next</label>
                          </div>
                        </div>
                      </div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </>
  );
}

export default ActivitylogPage;