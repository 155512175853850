import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import LoaderHelper from "../../UtilityComponent/Loading/LoaderHelper";
import AuthService from "../../Api_Module/Api_Services/AuthService";
import { alertErrorMessage, alertSuccessMessage, alertWarningMessage } from "../../UtilityComponent/CustomAlertMessage";
import { $ } from "react-jquery-plugin";
import { ProfileContext } from "../../Context";
import DataTableBase from "../../UtilityComponent/DataTable";


const FundPage = () => {
  const [fundData, setfundData] = useState([]);
  const [estimatedportfolio, setEstimatedportfolio] = useState();
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [selectedDeposit, setSelectDeposit] = useState("");
  const [selectedChain, setSelectedchain] = useState("");
  const [tradeHistory, settradeHistory] = useState([]);
  const [minDeposit, setminDeposit] = useState();
  const [walletHisDetail, setwalletHisDetail] = useState();
  const [tradeHisDetails, settradeHisDetails] = useState();
  const [currencyId, setcurrencyId] = useState();
  const [allChain, setallChain] = useState();
  const [walletAddress, setWalletAddress] = useState('');
  const [DepositHistory, setDepositHistory] = useState([]);
  const [WithdrawalHistory, setWithdrawalHistory] = useState([]);
  const [wihtdrawalDetails, setwihtdrawalDetails] = useState({ minWithdrawal: '', maxWithdrawal: '', withdrawalFee: '' });
  const [otp, setOtp] = useState('');
  const [skipCount, setSkipCount] = useState(0);
  const [limitCount, setLimitCount] = useState(0);
  const [comissionHistory, setComissionHistory] = useState([]);
  const { email, mobile, handleUserDetials } = useContext(ProfileContext);


  // ********* Get OTP ********** //
  const handleGetOtp = async (signId) => {
    if (!walletAddress || !withdrawAmount) {
      alertErrorMessage('Please enter withdraw amount and Wallet address');
      return;
    } else if (CheckWalletAddress) {
      alertErrorMessage('Please enter valid Wallet Address')
      return;
    }
    LoaderHelper.loaderStatus(true);
    await AuthService.withdrawalOtp(signId, walletAddress, withdrawAmount, selectedCurrency, 'verification').then(async (result) => {
      if (result?.success) {
        startTimer();
        alertSuccessMessage(result?.message);
      }
    });
  };

  // ********* Funds Data ********** //
  const getUserfunds = async (orderId) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getUserfunds(orderId).then((result) => {
      if (result?.success) {
        setfundData(result?.data)
      }
    })
  };

  // ********* Coin Deposit Address ********** //
  const getDepostAddress = async (currId, chain) => {
    setSelectedchain(chain);
    setSelectDeposit("");
    if (chain !== "BEP20") {
      alertErrorMessage(`Deposit is temporary unavailable on ${chain} `);
      return
    }

    LoaderHelper.loaderStatus(true);
    await AuthService.generateAddress(currId, chain).then((result) => {
      if (result?.success) {
        setSelectDeposit(result?.data)
      } else {
        $('#Deposit_modal')?.modal('hide');
      }
    })
  };

  // ********* Estimated Portfolio********** //
  const EstimatedPortfolio = async () => {
    await AuthService.estimatedPortfolio().then((result) => {
      if (result?.success) {
        setEstimatedportfolio(parseFloat(result?.data?.toFixed(4)))
      };
    })
  };

  // ********* Estimated Portfolio********** //
  const userComissionHistory = async (skip, limit) => {
    LoaderHelper.loaderStatus(true)
    await AuthService.comission_history(skip, 10).then((result) => {
      if (result?.success) {
        if (result?.data?.length > 0) {
          setSkipCount(skip);
          setLimitCount(limit);
          setComissionHistory(result?.data?.reverse())
          return;
        }
        else if (skip !== 0) {
          alertWarningMessage('No more data found')
          return;
        }
      };
    })
  };


  // ********* Trade History ********** //
  const TradeHistory = async (skip, limit) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.tradeHistory(skip, limit).then((result) => {
      if (result?.success) {
        if (result?.data?.length > 0) {
          setSkipCount(skip);
          setLimitCount(limit);
          settradeHistory(result?.data);
          return;
        } else if (skip !== 0) {
          alertWarningMessage('No more data found')
          return;
        }
      }
    })
  };
  // ********* Deposit Wallet History ********** //
  const DepositWalletHistory = async (skip, limit) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.walletDepositHistory(skip, limit).then(async (result) => {
      if (result?.success) {
        if (result?.data?.length > 0) {
          setSkipCount(skip);
          setLimitCount(limit);
          setDepositHistory(result?.data);
          return;
        } else if (skip !== 0) {
          alertWarningMessage('No more data found')
        }
      }
    });
  };
  // ********* Deposit Wallet History ********** //
  const WithdrawalWalletHistory = async (skip, limit) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.walletWithdrawalHistory(skip, limit).then(async (result) => {
      if (result?.success) {
        if (result?.data?.length > 0) {
          setSkipCount(skip);
          setLimitCount(limit);
          setWithdrawalHistory(result?.data);
          return;
        } else if (skip !== 0) {
          alertWarningMessage('No more data found')
        }
      }
    });
  };

  // ********* Coin Min Deposit Data ********** //
  const coinDetails = async (currId, type, data = {}) => {
    await AuthService.coinDetails(currId).then((result) => {
      if (result?.success) {
        setminDeposit(result?.data?.min_deposit);
        wihtdrawalDetails.minWithdrawal = result?.data?.min_withdrawal
        wihtdrawalDetails.maxWithdrawal = result?.data?.max_withdrawal
        wihtdrawalDetails.withdrawalFee = result?.data?.withdrawal_fee

        if (type === 'deposit') {
          if (result?.data?.deposit_status === "INACTIVE") {
            alertErrorMessage('Deposit is disabled for this Coin');
            return;
          } else {
            getDepostAddress(data?.currency_id, data?.chain[0]);
            $("#Deposit_modal").modal("show");
          }
        }

        if (type === 'withdrawal') {
          if (result?.data?.withdrawal_status === 'INACTIVE') {
            alertErrorMessage('Withdrawal is disabled for this Coin');
            return;
          } else {
            $("#Withdraw_modal").modal("show");
          }

        }
      }
    }
    )
  };


  // ********* Withdrawal Currency********** //
  const WithdrawalCurrency = async (currencyId, selectedChain, withdrawAmount, walletAddress, otp, signId) => {
    setOtp('');
    if (!email) {
      alertErrorMessage('Please update your email in Profile section for Withdrawal');
      return
    }
    LoaderHelper.loaderStatus(true);
    await AuthService.withdrawalCurrency(currencyId, selectedChain, withdrawAmount, walletAddress, otp, signId).then((result) => {
      if (result?.success) {
        getUserfunds()
        alertSuccessMessage(result?.message);
      }
    })
  };
  //******** Auto Call Verify -Deposit after evry 5 minute **********//
  const VerifyDeposit = async () => {
    const ref = window.location.href.split("/");
    ref[ref.length - 1] === 'FundPage' && setTimeout(VerifyDeposit, 300000);
    await AuthService.verifyDeposit().then(async (result) => {
      if (result?.success) {

      }
    });
  };
  //******** Wallet Address Validation **********//
  const [CheckWalletAddress, setCheckWalletAddress] = useState(false);
  const handleWalletAddress = (e) => {
    setCheckWalletAddress(true);
    setWalletAddress(e.target.value)
    if (selectedChain === 'BEP20' || selectedChain === 'RIK' || selectedChain === 'RIK20') {
      const bep20Regex = /^(0x)?[0-9a-fA-F]{40}$/;
      if (bep20Regex.test(e.target.value)) {
        setCheckWalletAddress(false);
      }
    } else if (selectedChain === 'TRC20') {
      const trc20Regex = /^(T|t)[A-Za-z1-9]{33}$/;
      if (trc20Regex.test(e.target.value)) {
        setCheckWalletAddress(false);
      }
    }
  };

  //****** */ Function to start the timer and disable the button***********//
  const [timer, setTimer] = useState(30);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const startTimer = () => {
    setIsButtonDisabled(true);
    setTimer(60);
  };
  useEffect(() => {
    let interval;
    if (timer > 0 && isButtonDisabled) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setIsButtonDisabled(false);
    }

    return () => {
      clearInterval(interval);
    };
  }, [timer, isButtonDisabled]);



  const handleWithdrawModal = (data) => {
    setcurrencyId(data.currency_id);
    setallChain(data.chain);
    setSelectedCurrency(data.short_name);
    setSelectedchain("BEP20");
    setWalletAddress('');
    setWithdrawAmount('');
    coinDetails(data?.currency_id, 'withdrawal');
    setCheckWalletAddress(false);
    setOtp('')
  };

  const handleDepositModal = (data) => {
    coinDetails(data?.currency_id, 'deposit', data);
    setSelectedCurrency(data.short_name);
    setallChain(data.chain);

    setcurrencyId(data.currency_id)
  };


  const fundsAction = (row) => {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <a href="#/" className="tb_btn badge badge-success m-1" onClick={() => handleDepositModal(row)}>Deposit</a>
        <a href="#/" className="tb_btn  badge badge-danger" onClick={() => handleWithdrawModal(row)}>Withdraw</a>
      </div>
    )

  };

  const comissionColumn = [
    { name: "Sr No.", selector: (row, index) => index + skipCount + 1 },
    { name: "Date/Time", wrap: true, selector: row => <Moment date={row?.createdAt} format="DD-MM-YYYY hh:mm" /> },
    { name: "Level", wrap: true, selector: row => `Level ${row.level}`, },
    { name: "From User", wrap: true, selector: row => row.from_user, },
    { name: "Currency", wrap: true, selector: row => row?.short_name, },
    { name: "Amount", wrap: true, selector: row => parseFloat((+row.amount).toFixed(6)), },
    { name: "Percentage", wrap: true, selector: row => row.percentage, },
  ];


  const fundsColumn = [
    { name: <>Sr No.</>, selector: (row, index) => index + 1 },
    { name: <>ASSETS</>, selector: row => row.short_name, },
    { name: <> Available Balance</>, wrap: true, sortable: true, selector: row => parseFloat(row?.balance?.toFixed(8)) },
    { name: <>Locked</>, wrap: true, sortable: true, selector: row => parseFloat(row?.locked_balance?.toFixed(8)) },
    { name: <>Total</>, wrap: true, sortable: true, selector: row => parseFloat((+row?.balance + +row?.locked_balance)?.toFixed(8)), },
    { name: <>Action</>, wrap: true, width: "200px", selector: fundsAction }
  ];

  const tradeHistoryColumn = [
    { name: "Sr No.", selector: (row, index) => index + skipCount + 1 },
    { name: "Date/Time", wrap: true, selector: row => <Moment date={row?.updatedAt} format="DD-MM-YYYY, h:mm:ss" />, },
    { name: "Trading Currency", wrap: true, selector: row => row?.currency },
    { name: "Side", wrap: true, selector: row => row?.side },
    { name: "Order Type", wrap: true, selector: row => row?.order_type, },
    { name: "Price", wrap: true, sortable: true, selector: row => parseFloat(row?.price) },
    { name: "Executed", wrap: true, sortable: true, selector: row => parseFloat(row?.quantity?.toFixed(8)) },
    { name: "Total", wrap: true, selector: row => parseFloat((row?.price * row?.quantity)?.toFixed(5)) },
    { name: "Transaction Type", wrap: true, selector: row => row?.transaction_type },
    { name: "Fee", wrap: true, sortable: true, selector: row => parseFloat(row?.fee?.toFixed(8)) }
  ];

  const depositColumn = [
    { name: <>Sr No.</>, selector: (row, index) => index + skipCount + 1 },
    { name: <>Date/Time</>, selector: row => <Moment date={row?.updatedAt} format="DD-MM-YYYY hh:mm" /> },
    { name: <>Coin</>, selector: row => row.short_name, },
    { name: <>Amount</>, selector: row => parseFloat(row.amount?.toFixed(5)), },
    { name: <>Transaction Type</>, selector: row => row.transaction_type, },
    { name: <>Status</>, selector: row => row.status, },
  ];

  const withdrawalColumn = [
    { name: <>Sr No.</>, selector: (row, index) => index + skipCount + 1 },
    { name: <>Date/Time</>, selector: row => <Moment date={row?.updatedAt} format="DD-MM-YYYY hh:mm" /> },
    { name: <>Coin</>, selector: row => row.short_name, },
    { name: <>Amount</>, selector: row => parseFloat(row.amount?.toFixed(5)), },
    { name: <>Transaction Type</>, selector: row => row.transaction_type, },
    { name: <>Status</>, selector: row => row.status, },
  ];

  const executeFuncInSequence = async () => {
    await getUserfunds();
    await handleUserDetials();
    await EstimatedPortfolio();
    await VerifyDeposit();
  }
  useEffect(() => {
    executeFuncInSequence()
  }, []);


  return (
    <>
      <section className="inner-page-banner">
        <div className="container">
          <div className="inner text-center">
            <h1 className="title">Funds</h1>
            <nav className="mt-4">
              <ol className="breadcrumb justify-content-center">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Wallet</li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <section >
        <div className="container">
          <div className="d-flex-between mb-5 custom_dlflex">
            <ul className="nav custom-tabs overflowx_scroll funds_tab">
              <li><a className=" active" data-bs-toggle="tab" href="#funds" onClick={getUserfunds}>Funds</a></li>
              <li><a data-bs-toggle="tab" href="#tt_history" onClick={() => { DepositWalletHistory(0, 10); setSkipCount(0); setLimitCount(0) }}> Deposit History</a> </li>
              <li><a data-bs-toggle="tab" href="#withdrwal_history" onClick={() => { WithdrawalWalletHistory(0, 10); setSkipCount(0); setLimitCount(0) }}> Withdrawal History</a> </li>
              <li><a data-bs-toggle="tab" href="#tradehistory" onClick={() => { TradeHistory(0, 10); setSkipCount(0); setLimitCount(0) }}>Trade History</a></li>
              <li><a data-bs-toggle="tab" href="#comissionHistory" onClick={() => { userComissionHistory(0, 10); setSkipCount(0); setLimitCount(0) }}>Commission History</a></li>
            </ul>
            <div className="d-flex-between bidder">
              <span>Estimated Portfolio : </span>
              <div className="d-flex-center ms-1">
                <span className="text-white">{estimatedportfolio}</span>
              </div>
            </div>
          </div>
          <div className="tab-content custom-tab-content p-0">
            <div className="tab-pane fade show container active form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded" id="funds">
              <div className="table-responsive">
                <DataTableBase columns={fundsColumn} data={fundData} />
              </div>
            </div>

            {/* Deposit History */}
            <div className="tab-pane container fade form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded" id="tt_history">
              <div className="table-responsive">
                <DataTableBase columns={depositColumn} data={DepositHistory} pointerOnHover onRowClicked={(row) => { setwalletHisDetail(row); $("#wallet_history").modal('show'); }} />
                {DepositHistory?.length !== 0 &&
                  <div className="container pt-3 pb-4  table_control" >
                    <div className=" row align-items-center " >
                      <div className=" row align-items-center justify-content-end" >
                        <div className="btn-group btn-group-mini justify-content-end " role="group" aria-label="Basic radio toggle button group">
                          <input type="button" className="btn-check" name="btnradio" id="btnradio250" autoComplete="off" disabled={skipCount <= 1} />
                          <label className="btn " htmlFor="btnradio250" onClick={() => { DepositWalletHistory(skipCount - 10, limitCount - 10) }}>Previous</label>

                          <input type="button" className="btn-check" name="btnradio" id="btnradio375" autoComplete="off" disabled={DepositHistory?.length < 10} />
                          <label className="btn " htmlFor="btnradio375" disabled onClick={() => { DepositWalletHistory(skipCount + 10, limitCount + 10) }}>Next</label>
                        </div>
                      </div>
                    </div>
                  </div>}
              </div>
            </div>

            {/* Withdrawal History */}
            <div className="tab-pane container fade form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded" id="withdrwal_history">
              <div className="table-responsive">
                <DataTableBase columns={withdrawalColumn} data={WithdrawalHistory} pointerOnHover onRowClicked={(row) => { setwalletHisDetail(row); $("#wallet_history").modal('show'); }} />
                {WithdrawalHistory?.length !== 0 &&
                  <div className="container pt-3 pb-4  table_control" >
                    <div className=" row align-items-center " >
                      <div className=" row align-items-center justify-content-end" >
                        <div className="btn-group btn-group-mini justify-content-end " role="group" aria-label="Basic radio toggle button group">
                          <input type="button" className="btn-check" name="btnradio" id="btnradio250" autoComplete="off" disabled={skipCount <= 1} />
                          <label className="btn " htmlFor="btnradio250" onClick={() => { WithdrawalWalletHistory(skipCount - 10, limitCount - 10) }}>Previous</label>

                          <input type="button" className="btn-check" name="btnradio" id="btnradio375" autoComplete="off" disabled={WithdrawalHistory?.length < 10} />
                          <label className="btn " htmlFor="btnradio375" disabled onClick={() => { WithdrawalWalletHistory(skipCount + 10, limitCount + 10) }}>Next</label>
                        </div>
                      </div>
                    </div>
                  </div>}
              </div>
            </div>

            {/* *****Trade History******** */}
            <div className="tab-pane container fade form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded" id="tradehistory">
              <div className="table-responsive">
                <DataTableBase subHeaderWrap headerWrap columns={tradeHistoryColumn} pointerOnHover data={tradeHistory} onRowClicked={(row) => { settradeHisDetails(row); $("#trade_history").modal('show'); }} />

                {tradeHistory?.length !== 0 &&
                  <div className="container pt-3 pb-4  table_control" >
                    <div className=" row align-items-center justify-content-end" >
                      <div className="col-md-4" >
                        <div className="btn-group btn-group-mini justify-content-end " role="group" aria-label="Basic radio toggle button group">
                          <input type="button" className="btn-check" name="btnradio" id="btnradio250" autoComplete="off" disabled={skipCount <= 1} />
                          <label className="btn " htmlFor="btnradio250" onClick={() => { TradeHistory(skipCount - 10, limitCount - 10) }}>Previous</label>
                          <input type="button" className="btn-check" name="btnradio" id="btnradio375" autoComplete="off"
                            disabled={tradeHistory?.length < 10} />
                          <label className="btn " htmlFor="btnradio375" disabled onClick={() => { TradeHistory(skipCount + 10, limitCount + 10) }}>Next</label>
                        </div>
                      </div>
                    </div>
                  </div>}
              </div>
            </div>


            {/* *****UserComission history******** */}
            <div className="tab-pane container fade form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded" id="comissionHistory">
              <div className="table-responsive">
                <DataTableBase className="rdt_Table rdt_TableHeader rdt_TableHead rdt_TableHeadRow rdt_TableRow rdt_TableCol" columns={comissionColumn} data={comissionHistory} />
                {comissionHistory?.length !== 0 &&
                  <div className="container pt-3 pb-4  table_control" >
                    <div className=" row align-items-center justify-content-end" >
                      <div className="col-md-4" >
                        <div className="btn-group btn-group-mini justify-content-end " role="group" aria-label="Basic radio toggle button group">
                          <input type="button" className="btn-check" name="btnradio" id="btnradio250" autoComplete="off" disabled={skipCount <= 1} />
                          <label className="btn " htmlFor="btnradio250" onClick={() => { userComissionHistory(skipCount - 10, limitCount - 10) }}>Previous</label>
                          <input type="button" className="btn-check" name="btnradio" id="btnradio375" autoComplete="off"
                            disabled={comissionHistory?.length < 10} />
                          <label className="btn " htmlFor="btnradio375" onClick={() => { userComissionHistory(skipCount + 10, limitCount + 10) }}>Next</label>
                        </div>
                      </div>
                    </div>
                  </div>}
              </div>
            </div>

          </div>
        </div>
      </section >
      <div className="modal fade" id="Deposit_modal" tabIndex="-1" aria-labelledby="Deposit_modalLaebl" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header flex-column px-8">
              <h3 className="modal-title" id="placeBitLaebl">
                Deposit Funds
              </h3>
              <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close">
                <i className="ri-close-fill"></i>
              </button>
            </div>
            <div className="modal-body px-8 py-5">
              <form action="#">
                <>
                  <div id="bnm">
                    <div className="btn-group btn_radio_group d-flex justify-content-center align-items-center m-auto " role="group" aria-label="Basic radio toggle button group">
                      {allChain ? allChain?.map((item, index) => {
                        return (
                          <button key={index} type="button" className={`btn btn-outline-primary ${selectedChain === item && "active"}`} htmlFor={item} onClick={() => { getDepostAddress(currencyId, item) }}>
                            {item === 'RIK' ? 'RIK20' : item}
                          </button>)
                      }) : ''}
                    </div>
                    <img alt="Error while fetching QR " src={selectedDeposit ? `https://quickchart.io/chart?chs=300x300&cht=qr&chl=${selectedDeposit}&choe=UTF-8` : "/images/qrscan.png"}
                      className="qr_img img-fluid" />
                    <input className="shareUrl-input copy_url js-shareUrl text-center" type="text" readOnly defaultValue={selectedDeposit} onClick={() => { navigator.clipboard.writeText(selectedDeposit); alertSuccessMessage('Copied!!') }}
                    />
                    <small className="text-center d-block mtb-2">
                      Click above to copy the Code.
                    </small>
                    <ul className="disclaimer mt-3">
                      <label>Disclaimer</label>
                      <li>
                        • Minimum deposit of {minDeposit} {selectedCurrency}, deposit
                        below that cannot be recovered.
                      </li>
                      <li>
                        • Please deposit only {selectedCurrency} on this
                        address. If you deposit any other coin, it will be
                        lost forever.
                      </li>
                      <li>
                        • This is {selectedChain === 'RIK' ? 'RIK20' : selectedChain} deposit address type.
                        Transferring to an unsupported network could result in
                        loss of deposit.
                      </li>
                    </ul>
                  </div>
                </>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Withdrwal modal first  */}
      <div className="modal fade" id="Withdraw_modal" tabIndex="-1" aria-labelledby="Withdraw_modalLaebl" aria-hidden="true" >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header flex-column px-8">
              <h3 className="modal-title" id="placeBitLaebl">
                Withdraw Funds
              </h3>
              <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close">
                <i className="ri-close-fill"></i>
              </button>
            </div>
            <div className="modal-body px-8 py-5">
              <>
                <div className="btn-group btn_radio_group d-flex justify-content-center align-items-center m-auto " role="group" aria-label="Basic radio toggle button group">
                  {allChain ? allChain?.map((item, index) => {
                    return (
                      <button key={index} type="button" className={`btn btn-outline-primary ${selectedChain === item && "active"}`} htmlFor={item} onClick={() => { item === "BEP20" ? setSelectedchain(item) : alertErrorMessage(`Withdrawal IS TEMPORARY UNAVAILABLE ON ${item}`) }}>
                        {item}
                      </button>)
                  }) : ''}
                </div>

                <div className="form-group mb-4">
                  <input type="text" name="wallet_Add" value={walletAddress} placeholder="Wallet Address" onChange={(e) => handleWalletAddress(e)} />
                  <small className="text-danger">{CheckWalletAddress && walletAddress ? 'Please enter valid Wallet Address' : ''}</small>
                </div>

                <div className="form-group mb-4">
                  <input type="number" name="amount_val" value={withdrawAmount} placeholder="Amount" onWheel={(e) => e.target.blur()} onChange={(e) => setWithdrawAmount(e.target.value)} />
                </div>
                <div className="field-box field-otp-box  mb-4">
                  <input type="number" name="wallet_Add" value={otp} placeholder="Enter OTP" onChange={(e) => setOtp(e.target.value)} onWheel={(e) => { e.target.blur() }} />
                  <button type="btn" className="btn btn-sm btn-gradient" disabled={isButtonDisabled} onClick={() => handleGetOtp(email ? email : mobile, true)}>
                    <span> {isButtonDisabled ? `Resend OTP in ${timer} sec` : 'Get OTP'}</span>
                  </button>
                  <small className="d-block text-center mt-2">OTP will sent to {email ? email : mobile}</small>
                </div>
                <ul className="disclaimer mt-3">
                  <label>Disclaimer</label>
                  <li>
                    • Minimum Withdrawal should be of {wihtdrawalDetails.minWithdrawal}
                  </li>
                  <li>
                    • Maximum Withdrawal should be of : {wihtdrawalDetails.maxWithdrawal}
                  </li>
                  <li>
                    • Withdrawal Fee will be: {wihtdrawalDetails.withdrawalFee}
                  </li>
                </ul>
                <div className="d-flex align-items-center justify-content-between mb-3">
                </div>
                <div className="form-group mb-4 d-flex justify-content-center align-items-center m-auto">
                  <button type="button" className="btn btn-success active" disabled={!withdrawAmount || !walletAddress || !otp || CheckWalletAddress} data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#Withdraw_modal2">
                    Withdraw
                  </button>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>

      {/* Withdrawal Modal */}
      <div className="modal fade" id="Withdraw_modal2" tabIndex="-1" aria-labelledby="Withdraw_modalLaebl" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header flex-column px-8">
              <h3 className="modal-title" id="placeBitLaebl">
                Confirm
              </h3>
              <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close" >
                <i className="ri-close-fill"></i>
              </button>
            </div>
            <div className="modal-body px-8 py-5">
              <>
                <div className="form-group mb-4">
                  <h4>
                    You are going to withdraw {selectedCurrency}{" "}
                    {withdrawAmount},
                    please confirm your withdraw by clicking 'Confirm' button
                    below.
                  </h4>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group mb-4">
                      <button type="button" className="btn btn-danger btn-small w-100 justify-content-center" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#Withdraw_modal2" >
                        <span>Cancels</span>
                      </button>
                    </div>
                  </div>

                  <div className="col">
                    <div className="form-group mb-4">
                      <button type="button" className="btn btn-success btn-small w-100 justify-content-center" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#Withdraw_modal2" onClick={() => { WithdrawalCurrency(currencyId, selectedChain, withdrawAmount, walletAddress, otp, email ? email : mobile) }}>
                        <span>Confirm</span>
                      </button>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>

      {/* TradeHistory modal */}
      <div className="modal fade" id="trade_history" tabIndex="-1" aria-labelledby="transfer_history" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header flex-column px-8">
              <h3 className="modal-title" id="placeBitLaebl">
                Trade Details
              </h3>
              <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close">
                <i className="ri-close-fill"></i>
              </button>
            </div>
            <div className="modal-body px-8 py-5 body_history">
              <div className="tt_data">
                <div className="tt_item ">
                  <span className="tt_disable">Date &amp; Time</span>
                  <span className="tt_normal">
                    <b><Moment date={tradeHisDetails?.updatedAt} format='MMMM Do YYYY, h:mm:ss a' /> </b>
                  </span>
                </div>
                <div className="tt_item">
                  <span className="tt_disable">Currency</span>
                  <span className="tt_normal">
                    <b>{tradeHisDetails?.currency}</b>
                  </span>
                </div>
              </div>
              <div className="tt_item ">
                <span className="tt_disable">Side</span>
                <span className="tt_normal">
                  <b>{tradeHisDetails?.side}</b>
                </span>
              </div>

              <div className="tt_data">
                <div className="tt_item">
                  <span className="tt_disable">Transaction Type</span>
                  <span className="tt_normal">
                    <b>{tradeHisDetails?.transaction_type}</b>
                  </span>
                </div>
                <div className="tt_item ">
                  <span className="tt_disable">
                    Fee
                  </span>
                  <span className="tt_normal">
                    <b>{tradeHisDetails?.fee}</b>
                  </span>
                </div>

                <div className="tt_item ">
                  <span className="tt_disable">Fee Type</span>
                  <span className="tt_normal">
                    <b>{tradeHisDetails?.fee_type}</b>
                  </span>
                </div>
                <div className="tt_item">
                  <span className="tt_disable">Amount</span>
                  <span className="tt_normal">
                    <b>{tradeHisDetails?.amount}</b>
                  </span>
                </div>
                <div className="tt_item ">
                  <span className="tt_disable">Order Type</span>
                  <span className="tt_normal">
                    <b>{tradeHisDetails?.order_type}</b>
                  </span>
                </div>

                <div className="tt_item ">
                  <span className="tt_disable">Quantity</span>
                  <span className="tt_normal">
                    <b>{tradeHisDetails?.quantity}</b>
                  </span>
                </div>
                <div className="tt_item ">
                  <span className="tt_disable">Order Id</span>
                  <span className="tt_normal">
                    <b>{tradeHisDetails?.order_id}</b>
                  </span>
                </div>


                <div className="tt_item ">
                  <span className="tt_disable">Remarks</span>
                  <span className="tt_normal">
                    <b>COINPOWER Exchange</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Wallet History modal */}
      <div className="modal fade" id="wallet_history" tabIndex="-1" aria-labelledby="transfer_history" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header flex-column px-8">
              <h3 className="modal-title" id="placeBitLaebl">
                Transaction Details
              </h3>
              <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close">
                <i className="ri-close-fill"></i>
              </button>
            </div>
            <div className="modal-body px-8 py-5 body_history">
              <div className="tt_data">
                <div className="tt_item">
                  <span className="tt_disable">Date/Time</span>
                  <span className="tt_normal">
                    <b><Moment date={walletHisDetail?.updatedAt} format='MMMM Do YYYY, h:mm:ss A' /></b>
                  </span>
                </div>
              </div>
              <div className="tt_data">
                <div className="tt_item">
                  <span className="tt_disable">Coin</span>
                  <span className="tt_normal">
                    <b>{walletHisDetail?.short_name}</b>
                  </span>
                </div>
              </div>
              <div className="tt_item ">
                <span className="tt_disable">Status</span>
                <span className="tt_normal">
                  <b>{walletHisDetail?.status}</b>
                </span>
              </div>

              <div className="tt_data">
                <div className="tt_item">
                  <span className="tt_disable">Transaction Type</span>
                  <span className="tt_normal">
                    <b>{walletHisDetail?.transaction_type}</b>
                  </span>
                </div>
                <div className="tt_item ">
                  <span className="tt_disable">
                    Fee<small> (Incl.of all applicable taxes)</small>
                  </span>
                  <span className="tt_normal">
                    <b>{walletHisDetail?.fee}</b>
                  </span>
                </div>
                <div className="tt_item">
                  <span className="tt_disable">Amount</span>
                  <span className="tt_normal">
                    <b>{walletHisDetail?.amount}</b>
                  </span>
                </div>
                <div className="tt_item ">
                  <span className="tt_disable">From Address</span>
                  <span className="tt_normal">
                    <b>{walletHisDetail?.from_address}</b>
                  </span>
                </div>
                <div className="tt_item ">
                  <span className="tt_disable">To Address</span>
                  <span className="tt_normal">
                    <b>{walletHisDetail?.to_address}</b>
                  </span>
                </div>

                <div className="tt_item ">
                  <span className="tt_disable">Transaction Hash</span>
                  <span className="tt_normal">
                    <b>{walletHisDetail?.transaction_hash}</b>
                  </span>
                </div>

                <div className="tt_item ">
                  <span className="tt_disable">Remarks</span>
                  <span className="tt_normal">
                    <b>COINPOWER Exchange</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default FundPage;
