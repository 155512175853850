import React, { useContext, useEffect, useRef, useState } from 'react';
import './index.css';
import { widget } from '../charting_library';
import Datafeed from './datafeed';
import { ProfileContext } from '../../Context';
import logo from './mini_icon.png'


export default function TVChartContainer({ symbol }) {
	const { tradeTheme } = useContext(ProfileContext)
	const functCheckRef = useRef(true);
	const [tvWidget, setTvWidget] = useState();

	const getChart = async (symbol) => {
		const activeClass = document.body.className;
		const FinalClass =activeClass;
		const widgetOptions = {
			symbol: `${symbol}`,
			load_last_chart: true,
			interval: '1',
			custom_css_url: 'css/style.css',
			fullscreen: false,
			timezone: 'Asia/Kolkata',
			container: 'TVChartContainer',
			datafeed: Datafeed,
			has_intraday: true,
			library_path: '/charting_library/',
			pricescale: 100000000,
			intraday_multipliers: ['1', '60'],
			history: {
				limit: 200, 
			},
			hide_resolution_in_legend: true,
			height: '395px',
			logo: {
				image: logo,
				link: "https://coinpower.in/trade/trade"
			},
			time_frames: [
				{ text: '6M', resolution: 'D', description: '6 Month' },
				{ text: '1Y', resolution: 'W', description: '1 Year' },
				{ text: '5Y', resolution: 'W', description: '5 Year' },
			],
			time_scale: {
				min_bar_spacing: 20
			},
			disabled_features: ["use_localstorage_for_settings", "adaptive_logo", "border_around_the_chart", 'header_symbol_search'],
			theme: FinalClass === 'theme-light' ? 'light' : 'dark',
			overrides: {
				"symbolInfo.priceFormatter": {
					precision: 4
				},
				"paneProperties.background": FinalClass === 'theme-light' ? '#ffffff' : "#131722",
				"paneProperties.backgroundType": "solid",
			},
			loading_screen: {
				backgroundColor: FinalClass === 'theme-light' ? '#ffffff' : "#242731",
			},

		};
		setTvWidget(new widget(widgetOptions));
	};


	useEffect(() => {
		if (symbol.split('/')[0] !== 'undefined') {
			if (functCheckRef.current) {
				getChart(symbol);
			}
			functCheckRef.current = false;
		};
	}, [symbol]);

	useEffect(() => {
		if (tvWidget) {
			tvWidget.onChartReady(() => {
				const chart = tvWidget.chart();
				if (chart) {
					chart.setSymbol(symbol, () => null);
				}
			});
		}
	}, [symbol]);

	useEffect(() => {
		if (tvWidget) {
			const activeClass = document.body.className;
			console.log("🚀 ~ useEffect ~ activeClass:", activeClass)
			if (activeClass === 'theme-light') {
				tvWidget.changeTheme("light")
			} else {
				tvWidget.changeTheme("dark")
			}
		}
	}, [tradeTheme]);

	return (
		<>
			<div id='TVChartContainer' />
		</>

	);
}