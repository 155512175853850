import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import AuthService from "../../Api_Module/Api_Services/AuthService";
import { alertErrorMessage, alertSuccessMessage, alertWarningMessage } from "../../UtilityComponent/CustomAlertMessage";
import DefaultInput from "../../UtilityComponent/DefaultInput";
import { matchPassword, notEqualsZero, validPassword, validPasswordLength } from "../../UtilityComponent/Validation";
import LoaderHelper from "../../UtilityComponent/Loading/LoaderHelper";
import { ProfileContext } from "../../Context";

const SecurityPage = () => {
    const { email, mobile } = useContext(ProfileContext);
    const [setActiveTab] = useOutletContext()
    const [togglePass, setTogglePass] = useState({ currPass: false, newPass: false, confPass: false });
    const [password, setPassword] = useState('');
    const [conPassword, setConPassword] = useState('');
    const [oldpassword, setOldpassword] = useState('');
    const [disableBtn, setDisbaleBtn] = useState(false);
    const [otp, setOtp] = useState("");
    const navigate = useNavigate();

    const handleInputChange = (event) => {
        switch (event.target.name) {
            case "password":
                setPassword(event.target.value);
                break;
            case "conPassword":
                setConPassword(event.target.value);
                break;
            case "oldpassword":
                setOldpassword(event.target.value);
                break;
            case "OTP":
                setOtp(event.target.value);
                break;
            default:
                break;
        }
    };

    const handleLogOut = () => {
        navigate("/login");
        sessionStorage.clear();
        window.location.reload();
    };

    const validatePassword = async () => {
        if (validPassword(oldpassword) !== undefined) { alertWarningMessage('Please enter valid current password'); return false }
        else if (validPassword(password) !== undefined) { alertWarningMessage('Please enter valid new password'); return false }
        else if (matchPassword(password, conPassword) !== undefined) { alertWarningMessage('Password does not match'); return false }
        else if (matchPassword(password, oldpassword) === undefined) { alertWarningMessage('New password should be different from current password'); return false }
        else if (!otp || +otp < 9999) { alertWarningMessage('Invalid verification code'); return false }
        else { return true }
    };

    const handleSecutity = async () => {
        let isPasswordValide = await validatePassword()
        if (!isPasswordValide) return;
        try {
            LoaderHelper.loaderStatus(true);
            const result = await AuthService.changePassword(oldpassword, password, conPassword, email, otp)
            if (result?.success) {
                alertSuccessMessage("Password has been changed successfully.");
                setTimeout(() => {
                    handleLogOut();
                }, 1000);

            }
        } catch (error) { return }
    };

    const handlePassToggle = (type) => {
        switch (type) {
            case 'currPass':
                setTogglePass({ ...togglePass, currPass: !togglePass.currPass })
                break;
            case 'newPass':
                setTogglePass({ ...togglePass, newPass: !togglePass.newPass })
                break;
            case 'confPass':
                setTogglePass({ ...togglePass, confPass: !togglePass.confPass })
                break;

            default:
                break;
        }
    };

    const handleGetOtp = async () => {
        if (!email) {
            alertWarningMessage('Please try again later')
            return
        }

        LoaderHelper.loaderStatus(true);
        await AuthService.getOtp(email || mobile).then(async (result) => {
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                alertSuccessMessage(`OTP sent to ${email || mobile}`);
                setDisbaleBtn(true);
            }
        });
    };

    useEffect(() => {
        let URL = window.location.href?.split('/');
        let route = URL.pop();
        setActiveTab(route)

    }, []);
    return (
        <>
            <div class="tab-pane" id="SecurityPill" role="tabpanel" aria-labelledby="Security-pill">
                <div class="upload-formate mb-6 d-flex justify-content-center align-items-center">
                    <div>
                        <h3 class="mb-1 text-center">
                            Security
                        </h3>
                        <p class="formate mb-0">
                            Fill the form below to change your password.
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-lg-8 m-auto">
                        <div className="form-field-wrapper switch_btn  border-dashed border-gray-300 bg-lighten card-rounded p-4">
                            <form>
                                <div className="row">
                                    <div className="col-md-12 mb-4">
                                        <label htmlFor="email" className="form-label">
                                            Verification Code<span style={{ color: "red" }}>*</span>
                                        </label>
                                        <div className=" field-otp-box">
                                            <input id="OTP" name="OTP" type="number" placeholder="Enter OTP" value={otp} onWheel={(e) => e.target.blur()} onChange={handleInputChange} />
                                            <button type="button" className="btn btn-sm btn-gradient" onClick={handleGetOtp} >
                                                <span>{disableBtn ? 'Resend OTP' : 'GET OTP '}</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-4">
                                        <label htmlFor="text" className="form-label" >Current Password <span className="text-danger">*</span></label>
                                        <div className="field-otp-box">
                                            <DefaultInput type={togglePass.currPass ? "text" : "password"} name="oldpassword" aria-describedby="password" placeholder="Enter Current Password" value={oldpassword} onChange={handleInputChange} />

                                            <a className="show_password opt_btn btn-sm" href="#/" onClick={() => handlePassToggle("currPass")}>
                                                {togglePass.currPass ? <i className="ri-eye-off-line" /> : <i className="ri-eye-line" />}
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-4">
                                        <label htmlFor="text" className="form-label">New Password <span className="text-danger">*</span></label>
                                        <div className="field-otp-box">
                                            <DefaultInput type={togglePass.newPass ? "text" : "password"} name="password" placeholder="Enter New Password" value={password} errorstatus={(validPassword(password) !== undefined && notEqualsZero(password) && validPasswordLength(password)) ? 'true' : 'false'} errormessage={validPassword(password) && validPasswordLength(password)} onChange={handleInputChange} />

                                            <a className="show_password opt_btn btn-sm" onClick={() => handlePassToggle("newPass")} href="#/" >
                                                {togglePass.newPass ? <i className="ri-eye-off-line" /> : <i className="ri-eye-line" />}
                                            </a>

                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-4">
                                        <label htmlFor="text" className="form-label">Confirm New Password <span className="text-danger">*</span></label>
                                        <div className="field-otp-box">
                                            <DefaultInput type={togglePass.confPass ? "text" : "password"} name="conPassword" placeholder="Enter Confirm Password" value={conPassword} errorstatus={(notEqualsZero(conPassword) && matchPassword(password, conPassword)) ? 'true' : 'false'} errormessage={matchPassword(password, conPassword)} onChange={handleInputChange} />

                                            <a className="show_password opt_btn btn-sm" href="#/" onClick={() => handlePassToggle("confPass")}>
                                                {togglePass.confPass ? <i className="ri-eye-off-line" /> : <i className="ri-eye-line" />}
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-4 mt-4">
                                        <div className="field-box">
                                            {(!oldpassword || !password || !conPassword || !otp) ? <button className="btn btn-gradient w-100 justify-content-center btn-medium " type="button" id="inValid"   ><span>Submit</span></button> : <button className="btn btn-gradient w-100 justify-content-center btn-medium " type="button"
                                                onClick={handleSecutity}
                                            ><span>Submit</span></button>}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SecurityPage;