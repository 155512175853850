import React, { useEffect } from "react";
import { Link } from "react-router-dom";
const Earn = () => {

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);


    return (
        <>


            <div className="about_page clist refer_page_main" >
                <section className="about_top referal_bg" >
                    <div className="container" >
                        <div className="row g-md-5 align-items-center" >
                            <div className="col-lg-6" >
                                <h2 className="text-warning" >
                                    Refer and Earn crypto <br /> <span className="" >  with COINPOWER</span>
                                </h2>
                                <h4> Refer your friends to earn <b className="text-warning" >upto 40% commission</b>  on every signup</h4>
                                <Link to='/signup' className="btn btn-gradient btn-xl mt-3" > <span>Sign Up Now</span> </Link>
                            </div>
                            <div className="col-lg-6" >
                                <img alt="" src="/images/refer_img.svg" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </section>


                <div className="container  my-5 py-5" >
                    <div className=" wallet-block" >
                        <div className="card-body" >
                            <section className="hurry_banner">
                                <div className="container">
                                    <h3 className=" ">Hurry! This limited-time opportunity is available for the first <strong>50,000</strong> sign-ups.</h3>
                                </div>
                            </section>




                            <section className=" hiw_sec ">
                                <div className="container">
                                    <div className="section-title section-title-center text-center">
                                        <h2 className="text-gradient"> How Referral Works </h2>
                                        <p> Refer & Earn COINPOWERin just 3 simple steps  </p>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-6">
                                            <div className="wallet-block text-center hiw_card hiw_card_bar">
                                                <div className="thumb m-auto">
                                                    <img src="/images/hiw_1.png" alt="nft wallet" />
                                                </div>
                                                <span>Step 1</span>
                                                <h4 className="title text-gradient">Sign-Up </h4>
                                                <p>Signup on Coinpower Exchange </p>
                                            </div>
                                        </div>
                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-6">
                                            <div className="wallet-block text-center hiw_card hiw_card_bar">
                                                <div className="thumb m-auto">
                                                    <img src="/images/step_2.png" alt="nft wallet" />
                                                </div>
                                                <span>Step 2</span>
                                                <h4 className="title text-gradient"> Get your Referral Link  </h4>
                                                <p>
                                                    Once you sing up your referral link is
                                                    generated under refer and earn tab, you
                                                    can copy your referral link and share it
                                                    with your friends to earn more COINPOWER

                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-6">
                                            <div className="wallet-block text-center hiw_card">
                                                <div className="thumb m-auto">
                                                    <img src="/images/step_3.png" alt="nft wallet" />
                                                </div>
                                                <span>Step 3</span>
                                                <h4 className="title text-gradient"> Earn Referral Rewards </h4>
                                                <p>Earn upto 40% Commission: Every time you refer a friend to COINPOWER. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>



                <div className="container">
                    <div className="newsletter-content refering_sec">
                        <h2>Start Referring Start Earning!  </h2>
                        <Link to="/login" className="btn btn-xl btn-gradient "  >
                            <span>Log In</span>
                        </Link>
                    </div>
                </div>









            </div>
        </>
    )
}

export default Earn
