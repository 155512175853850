import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const authToken = sessionStorage.getItem("COINPOWER_AUTH_TOKEN");

  return (
    <footer className="footer-wrapper">
      <div className="footer-inner">
        <div className="container">
          <div className="row">

            <div className="col-lg-3 col-md-6 mb-4">
              <div className="footer-widget">
                <h4>About Us</h4>
                <ul className="footer-list-widget">
                  <li>
                    <Link to="/AboutUs">About </Link>
                  </li>
                  <li>
                    <Link to="/TermsOfUsePage">Terms of Use</Link>
                  </li>
                  <li>
                    <Link to="/PrivacyPolicyPage">Privacy & KYC Policy</Link>
                  </li>
                  <li>
                    <Link to="/RiskDisclosure">Risk Disclosure</Link>
                  </li>
                  {/* <li>
                    <Link to="/announcements">Announcements</Link>
                  </li>
                  <li>
                    <Link to="/bloglist">COINPOWER Blog</Link>
                  </li>
                  <li>
                    <Link to="/COINPOWERVentures">COINPOWER Ventures</Link>
                  </li> */}
                  {/* <li>
                    <Link to="https://blogs.COINPOWER.io/"  target="_blank" >Blogs</Link>
                  </li> */}
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 mb-4">
              <div className="footer-widget">
                <h4>Services</h4>
                <ul className="footer-list-widget">
                  <li>
                    <Link to="/BuyCrypto">Buy Crypto</Link>
                  </li>
                  <li>
                    <Link to="/Fees">Fees</Link>
                  </li>
                  <li>
                    <Link to={authToken ? "/ReferralPage" : "/Earn"}>Referral Program</Link>
                  </li>
                  <li>
                    <Link to="/listingcoin">Listing Application</Link>
                  </li>
                  {/* <li>
                    <Link to="/affiliates">Affiliate</Link>
                  </li>
                  <li>
                    <Link to="/VIPServices">VIP Services</Link>
                  </li> */}
                  {/* <li>
                    <Link to="/DownloadCOINPOWER">Download COINPOWER</Link>
                  </li> */}

                </ul>
              </div>
            </div>

            {/* <div className="col-lg-3 col-md-6 mb-4">
              <div className="footer-widget">
                <h4>Support</h4>
                <ul className="footer-list-widget">
                  <li>
                    <Link to="/LawEnforcementReq">Law Enforcement Requests</Link>
                  </li>
                  <li>
                    <Link to="/CryptoInformation">Crypto Information</Link>
                  </li>
                  <li>
                    <Link to="/LawEnforcementGuide">Law Enforcement Guidelines</Link>
                  </li>
                </ul>
              </div>
            </div> */}

            <div className="col-lg-3 col-md-6 mb-4">
              <div className="footer-widget">
                <h4>Contact Us</h4>
                <ul className="footer-list-widget">
                  <li>
                    <Link to="/FAQ">Help Center/ FAQ</Link>
                  </li>
                  <li>
                    <Link to="/Career">Career</Link>
                  </li>
                  <li>
                    <Link to="/Support">Submit a Request</Link>
                  </li>
                  {/* <li>
                    <Link to="mailto:info@COINPOWER.io?subject=Business Collaboration">Let’s Collaborate (Businesses)</Link>
                  </li>
                  <li>
                    <Link to="mailto:info@COINPOWER.io?subject=Institution Collaboration">Let’s Collaborate (Institutions)</Link>
                  </li>
                  <li>
                    <Link to="mailto:info@COINPOWER.io?subject=Media Collaboration">Media Collaboration</Link>
                  </li>
                  <li>
                    <Link to="mailto:info@COINPOWER.io?subject=Feedback and Suggestion">Feedback and Suggestions</Link>
                  </li> */}

                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 mb-4">
              <div className="footer-widget">
                <h4>Community</h4>
                <ul className="footer-list-widget footer_social">
                  <li>

                    <a rel="noreferrer" target="_blank" href="https://www.facebook.com">
                      <img alt="facebook" src="/images/socialMediaIcons/facebook.png" />
                    </a>
                  </li>
                  <li>
                    <a rel="noreferrer" target="_blank" href="https://t.me">
                      <img alt="Telegram" src="/images/socialMediaIcons/telegram.png" />
                    </a>
                  </li>
                  <li>
                    <a rel="noreferrer"
                      target="_blank"
                      href="https://twitter.com"
                    >
                      <img alt="Twitter" src="/images/socialMediaIcons/twitter.png" />
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href=" https://www.youtube.com">
                      <img alt="Youtube" src="/images/socialMediaIcons/youtube.png" />
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href=" https://www.instagram.com">
                      <img alt="Instagram" src="/images/socialMediaIcons/instagram.png" />
                    </a>
                  </li>
           
                </ul>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className="copyright text-center">
        <div className="container">
          <p>
            Copyright ©️ COINPOWER 2024. All Right Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
