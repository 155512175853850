import React, { useState, useEffect, useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import { ApiConfig } from "../../Api_Module/Api_Config/ApiEndpoints";
import "swiper/css";
import "swiper/css/pagination";
import { SocketContext } from "../../UtilityComponent/SocketContext";
import { Link } from "react-router-dom";
import LoaderHelper from "../../UtilityComponent/Loading/LoaderHelper";
import AuthService from "../../Api_Module/Api_Services/AuthService";

const LandingPage = () => {
  const [upperPairData, setUpperPairData] = useState([]);
  const [bannerImages, setBannerImages] = useState([]);
  const { socket } = useContext(SocketContext);

  useEffect(() => {
    let interval;
    if (socket) {
      let payload = {
        'message': 'market',
      }
      socket.emit('message', payload);
      interval = setInterval(() => {
        let payload = {
          'message': 'market',
        }
        socket.emit('message', payload);
      }, 1000)

      socket.on('message', (data) => {
        setUpperPairData(data?.pairs.sort((a, b) => {
          if (a.base_currency === "GTR" || a.base_currency === "SLX" || a.base_currency === "MVX") return -1;
          return 0;
        })?.slice(0, 10));
      });
    }
    return (() => {
      clearInterval(interval)
    })
  }, [socket]);



  const bannerList = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.bannerList().then(async result => {
      if (result?.success) {
        setBannerImages(result.data);
      }
    });
  };



  useEffect(() => {
    bannerList()
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <>
      <section className="hero-section hero-banner-style top-section-gap bg-1 " id="section_1">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-9 m-auto mb-5 text-center">
              <div className="banner-content">
                <h1 className="title  ms-auto me-auto text-center">
                  <small className="d-block">Fastest and Easiest way to</small>
                  <em className="text-gradient"> Buy, Sell & Trade</em>
                </h1>
                <p>Your Gateway to the world of Crypto Trading</p>
                <div className="group-btn mt-6">
                  <a href="/trade/Landing_page" className="btn btn-gradient">
                    <span>Get Start</span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="poster_slider">
            <Swiper
              className="market_slider  pb-11"
              spaceBetween={10}
              loop={true}
              autoplay={{
                delay: 2000,
              }}
              pagination={{
                dynamicBullets: true,
              }}
              modules={[Autoplay, Pagination, Navigation]}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
              }}>
              {bannerImages?.map((item) => {
                return (
                  <SwiperSlide key={item?._id}>
                    <a href="#/" className="main_poster">
                      <img alt="" src={ApiConfig.appUrl + item?.banner_path} />
                    </a>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
        </div>



        <section className=" market_update market_update_table pt-40 pb-40">
          <div className="container">
            <div className="section-title section-title-center text-center">
              <h2 className="text-gradient">Market Update</h2>
              <p>
                Never miss a beat in the crypto world with our real-time market
                updates
              </p>
            </div>
          </div>
          <div className="container">
            <div className="table-responsive l_table">
              {upperPairData ?
                <table className="table table_home ">
                  <thead>
                    <tr>
                      <th> Pair</th>
                      <th> Price</th>
                      <th> 24H Change</th>
                      <th> 24H High</th>
                      <th> 24H Low</th>
                      <th> 24H Vol</th>
                      <th> 24H Turnover</th>
                      <th> Operation</th>
                    </tr>
                  </thead>
                  <tbody>
                    {upperPairData.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {" "}
                          <div className="td_div">
                            <img alt=""
                              src={ApiConfig.appUrl + item?.icon_path}
                              className="img-fluid icon_img coinimg me-2 "
                            />
                            {item?.base_currency}/{item?.quote_currency}
                          </div>
                        </td>
                        <td>
                          <b>{item?.buy_price}</b>
                        </td>
                        <td
                          className={item?.change >= 0 ? "color-green text-success" : "color-red text-danger"}>
                          <b>{parseFloat(item?.change?.toFixed(5))} </b>
                        </td>
                        <td>
                          <b>{item?.high}</b>
                        </td>
                        <td>
                          <b>{item?.low}</b>
                        </td>
                        <td>
                          <b>{parseFloat((item?.volume?.toFixed(5)))}</b>
                        </td>
                        <td>
                          <b>{parseFloat((item?.buy_price * item?.volume)?.toFixed(5))}</b>
                        </td>
                        <td className="text-right" >
                          <a href={`/trade/${item?.base_currency}_${item?.quote_currency}`} className="btn btn-theme btn-sm my-0">
                            Trade
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table> : null}
            </div>
          </div>
        </section>


      </section>



      <section className=" wactp_sec ">
        <div className="container">
          <div className="section-title section-title-center text-center">
            <h2>
              World Class Trading
              <span className="text-gradient"> Platform </span>
            </h2>
          </div>
          <div className="row justify-content-center">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-6">
              <div className="wallet-block h-100">
                <div className="thumb">
                  <img src="/images/feature/1.png" alt="nft wallet" className="" />
                </div>
                <h4 className="title text-gradient">Exchange</h4>
                <p>
                  Ready to trade? Login to your account and start buying and
                  selling crypto currency today.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-6">
              <div className="wallet-block h-100">
                <div className="thumb">
                  <img src="/images/feature/2.png" alt="nft wallet" />
                </div>
                <h4 className="title text-gradient">High performance</h4>
                <p>
                  Securely access your account with rapid login times and high
                  performance servers.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-6">
              <div className="wallet-block h-100">
                <div className="thumb">
                  <img src="/images/feature/3.png" alt="nft wallet" />
                </div>
                <h4 className="title text-gradient">Super Fast KYC</h4>
                <p>
                  Get verified in just a few clicks and start trading without
                  any delay.
                </p>
              </div>
            </div>
            {/* <!-- End .col --> */}
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-6">
              <div className="wallet-block h-100">
                <div className="thumb">
                  <img src="/images/feature/4.png" alt="nft wallet" />
                </div>
                <h4 className="title text-gradient">Order Types</h4>
                <p>
                  Take advantage of market opportunities with our comprehensive
                  range of order type.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-6">
              <div className="wallet-block h-100">
                <div className="thumb">
                  <img src="/images/feature/5.png" alt="nft wallet" />
                </div>
                <h4 className="title text-gradient">Customizable Interface</h4>
                <p>
                  Create a trading interface that works for you, with our
                  customizable dashboards and tools.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-6">
              <div className="wallet-block h-100">
                <div className="thumb">
                  <img src="/images/feature/6.png" alt="nft wallet" />
                </div>
                <h4 className="title text-gradient">Safe and Secure</h4>
                <p>
                  With our commitment to safety and security, you can trust that
                  your assets are protected on our secure crypto exchange.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className=" joc_sec btn-gradient ">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="section-title mb-0 ">
                <h2 className="text-dark">Join Our Community</h2>
                <p className="text-dark mb-0">
                  COINPOWER Exchange is global. Join the conversation in any of
                  our worldwide communities.
                </p>
                <div className="d-flex  joc_social_row ">
                  <a rel="noreferrer" className="joc_social" target="_blank" href="https://www.facebook.com" >
                    <i className="ri-facebook-line  ri-xl mb-2"></i>
                    <span>Facebook</span>
                  </a>
                  <a rel="noreferrer" className="joc_social" target="_blank" href="https://twitter.com">
                    <i className="ri-twitter-line  ri-xl mb-2"></i>
                    <span>Twitter</span>
                  </a>
                  <a rel="noreferrer" className="joc_social" target="_blank" href="https://www.instagram.com">
                    <i className="ri-instagram-line  ri-xl mb-2"></i>
                    <span>Instagram</span>
                  </a>
                  <a rel="noreferrer" className="joc_social" target="_blank" href="https://www.youtube.com/">
                    <i className="ri-youtube-line  ri-xl mb-2"></i>
                    <span>Youtube</span>
                  </a>
                  <a className="joc_social" target="_blank" href="https://t.me/" rel="noreferrer">
                    <i className="ri-telegram-line  ri-xl mb-2"></i>
                    <span>Telegram</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <img src="/images/joc_img.svg" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className=" hiw_sec ">
        <div className="container">
          <div className="section-title section-title-center text-center">
            <h2 className="text-gradient">How it work</h2>
            <p>Follow these simple steps , and start your trading journey</p>
          </div>
          <div className="row justify-content-center">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 mb-6">
              <div className="wallet-block text-center hiw_card hiw_card_bar">
                <div className="thumb m-auto">
                  <img src="/images/hiw_1.png" alt="nft wallet" />
                </div>
                <span>Step 1</span>
                <h4 className="title text-gradient">Login & Register</h4>
                <p>Enter your email address and create a strong password.</p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 mb-6">
              <div className="wallet-block text-center hiw_card hiw_card_bar">
                <div className="thumb m-auto">
                  <img src="/images/hiw_2.png" alt="nft wallet" />
                </div>
                <span>Step 2</span>
                <h4 className="title text-gradient">Complete KYC</h4>
                <p>
                  Complete the two-factor authentication process (2FA). Wait for
                  your account to be verified and approved .
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 mb-6">
              <div className="wallet-block text-center hiw_card">
                <div className="thumb m-auto">
                  <img src="/images/hiw_3.png" alt="nft wallet" />
                </div>
                <span>Step 3</span>
                <h4 className="title text-gradient"> Start Trading </h4>
                <p>Once approved, login to your account and start trading.</p>
              </div>
            </div>
          </div>
        </div>
      </section>


      <div className="container">
        <div className="newsletter-content">
          <div className="row st_sec" >
            <div className="col-lg-6" >
              <div className="st_txt" >
                <h2>START TRADING <br /> NOW </h2>
                <div className="btn-row" >
                  <Link className="btn btn-gradient px-5 " to="/signup"><span> Get Started    </span></Link>
                  <Link className="btn btn-light  px-10  " to="/login"><span> Sign In  </span></Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6" >
              <img src="/images/booster_img.svg" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
