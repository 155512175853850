import { ApiConfig } from "../Api_Config/ApiEndpoints";
import { ApiCallGet, ApiCallPost, ApiCallPut } from "../Api_Config/ApiCall";

const AuthService = {
  /*** Calling Api's **/

  login: async (signId, password, captchaCode) => {
    const { baseAuth, login } = ApiConfig;
    const url = baseAuth + login;
    const params = {
      email_or_phone: signId,
      password: password,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  register: async (signId, password, cPassword, otp, invitation, countryCode, captchaCode) => {
    const { baseAuth, register } = ApiConfig;
    const url = baseAuth + register;
    const params = {
      country_code: countryCode,
      email_or_phone: signId,
      password: password,
      confirm_password: cPassword,
      referral_code: invitation,
      verification_code: otp,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  getCode: async (signId, type, otp) => {
    const { baseAuth, getcode } = ApiConfig;
    const url = baseAuth + getcode;
    const params = {
      "email_or_phone": signId,
      "type": type,
      "otp": otp
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  getOtp: async (signid, type) => {
    const { baseAuth, getOtp } = ApiConfig;
    const url = baseAuth + getOtp;
    const params = {
      email_or_phone: signid,
      "resend": true,
      type: type
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  withdrawalOtp: async (signId, walletAddress, withdrawAmount, selectedCurrency, type) => {
    const { baseAuth, getOtp } = ApiConfig;
    const url = baseAuth + getOtp;
    const params = {
      email_or_phone: signId,
      amount: withdrawAmount,
      short_name: selectedCurrency,
      address: walletAddress,
      type: type,
      "resend": true,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  otpVerify: async (otp, userId, emailId, first, last, type) => {
    const { baseUrl, otpVerify } = ApiConfig;
    const url = baseUrl + otpVerify;
    const params = {
      userId: userId,
      emailId: emailId,
      firstName: first,
      lastName: last,
      motp: otp,
      otp: true,
      otpType: type,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  referralLink: async () => {
    const token = sessionStorage.getItem("COINPOWER_AUTH_TOKEN");
    const { baseAuth, referalcode } = ApiConfig;
    const url = baseAuth + referalcode;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };
    return ApiCallGet(url, headers);
  },

  userReferralList: async (code) => {
    const token = sessionStorage.getItem("COINPOWER_AUTH_TOKEN");
    const { baseAuth, userReferralList } = ApiConfig;
    const url = baseAuth + userReferralList + `?code=${code}`;

    const headers = {
      "Content-Type": "application/json",
      'Authorization': token
    };
    return ApiCallGet(url, headers);
  },

  getDetails: async () => {
    const token = sessionStorage.getItem("COINPOWER_AUTH_TOKEN");
    const { baseAuth, getDetails } = ApiConfig;
    const url = baseAuth + getDetails;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },
  activityLogs: async (skip, limit) => {
    const token = sessionStorage.getItem("COINPOWER_AUTH_TOKEN");
    const { baseAuth, activityLogs } = ApiConfig;
    const url = baseAuth + activityLogs + `?skip=${skip}&limit=${limit}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  updateSettings: async (formData) => {
    const token = sessionStorage.getItem("COINPOWER_AUTH_TOKEN");
    const { baseAuth, updateSettings } = ApiConfig;
    const url = baseAuth + updateSettings;
    const headers = {
      'Content-Type': 'multipart/form-data',
      'Authorization': token
    };
    return ApiCallPut(url, formData, headers);
  },

  googleAuth: async () => {
    const token = sessionStorage.getItem("COINPOWER_AUTH_TOKEN");
    const { baseAuth, googleAuth } = ApiConfig;
    const url = baseAuth + googleAuth;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  update2fa: async (authType, code, verifyType) => {
    const token = sessionStorage.getItem("COINPOWER_AUTH_TOKEN");
    const { baseAuth, update2fa } = ApiConfig;
    const url = baseAuth + update2fa;
    const params = {
      type: authType,
      verification_code: +code,
      email_or_phone: verifyType
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url, params, headers);
  },

  getHistoricalData: async (base, quote, from, to) => {
    const token = sessionStorage.getItem('COINPOWER_AUTH_TOKEN');
    const { baseExchange, getHistoricalData } = ApiConfig;
    const url = baseExchange + getHistoricalData;
    const params = {
      base_currency: base,
      quote_currency: quote,
      from: from,
      to: to,
      limit: 2000,
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },
  cryptoCompareApi: async (base, quote, to, resolution) => {
    const url = `https://min-api.cryptocompare.com/data/v2/${resolution}?fsym=${base}&tsym=${quote}&toTs=${to}&limit=2000`
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallGet(url, headers);
  },

  placeOrder: async (infoPlaceOrder, buyprice, buyamount, base_currency_id, quote_currency_id, side) => {
    const token = sessionStorage.getItem('COINPOWER_AUTH_TOKEN');
    const { baseExchange, placeOrder } = ApiConfig;
    const url = baseExchange + placeOrder;
    const params = {
      "order_type": infoPlaceOrder,
      "base_currency_id": base_currency_id,
      "quote_currency_id": quote_currency_id,
      "side": side,
      "price": +buyprice,
      "quantity": +buyamount
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  getUserfunds: async () => {
    const token = sessionStorage.getItem("COINPOWER_AUTH_TOKEN");
    const { baseWallet, userfunds } = ApiConfig;
    const url = baseWallet + userfunds;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  generateAddress: async (currID, chain) => {
    const token = sessionStorage.getItem("COINPOWER_AUTH_TOKEN");
    const { baseWallet, generateAddress } = ApiConfig;
    const url = baseWallet + generateAddress;
    const params = {
      "currency_id": currID,
      "chain": chain
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url, params, headers);
  },
  withdrawalCurrency: async (currencyId, selectedChain, withdrawAmount, walletAddress, otp, signId) => {
    const token = sessionStorage.getItem("COINPOWER_AUTH_TOKEN");
    const { baseWallet, withdrawalCurrency } = ApiConfig;
    const url = baseWallet + withdrawalCurrency;
    const params = {
      "address": walletAddress,
      "amount": +withdrawAmount,
      "chain": selectedChain,
      "currency_id": currencyId,
      "email_or_phone": signId,
      "otp": otp,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },
  verifyDeposit: async () => {
    const token = sessionStorage.getItem("COINPOWER_AUTH_TOKEN");
    const { baseWallet, verifyDeposit } = ApiConfig;
    const url = baseWallet + verifyDeposit;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },
  walletDepositHistory: async (skip, limit) => {
    const token = sessionStorage.getItem("COINPOWER_AUTH_TOKEN");
    const { baseTrans, walletDepositHistory } = ApiConfig;
    const url = baseTrans + walletDepositHistory;
    const params = {
      skip: skip,
      limit: limit
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);

  },
  walletWithdrawalHistory: async (skip, limit) => {
    const token = sessionStorage.getItem("COINPOWER_AUTH_TOKEN");
    const { baseTrans, walletWithdrawalHistory } = ApiConfig;
    const url = baseTrans + walletWithdrawalHistory;
    const params = {
      skip: skip,
      limit: limit
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);

  },
  skipModel: async (skip) => {
    const token = sessionStorage.getItem("COINPOWER_AUTH_TOKEN");
    const { baseAuth, skipModel } = ApiConfig;
    const url = baseAuth + skipModel;
    const params = {
      check: skip,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);

  },

  estimatedPortfolio: async () => {
    const token = sessionStorage.getItem("COINPOWER_AUTH_TOKEN");
    const { baseWallet, estimatedPortfolio } = ApiConfig;
    const url = baseWallet + estimatedPortfolio;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  pastOrder: async (base_currency_id, quote_currency_id) => {
    const token = sessionStorage.getItem('COINPOWER_AUTH_TOKEN');
    const { baseExchange, pastOrder } = ApiConfig;
    const url = baseExchange + pastOrder;
    const params = {
      "base_currency_id": base_currency_id,
      "quote_currency_id": quote_currency_id,
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },
  userTradeHistory: async (base_currency_id, quote_currency_id) => {
    const token = sessionStorage.getItem("COINPOWER_AUTH_TOKEN");
    const { baseExchange, userTradeHistory } = ApiConfig;
    const url = baseExchange + userTradeHistory;
    const params = {
      "base_currency_id": base_currency_id,
      "quote_currency_id": quote_currency_id,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);

  },
  addkyc: async (formData) => {
    const token = sessionStorage.getItem('COINPOWER_AUTH_TOKEN');
    const { baseAuth, addkyc } = ApiConfig;
    const url = baseAuth + addkyc;

    const headers = {
      'Content-Type': 'multipart/form-data',
      "Authorization": token,
    };
    return ApiCallPost(url, formData, headers);
  },
  tradeHistory: async (skip, limit) => {
    const token = sessionStorage.getItem('COINPOWER_AUTH_TOKEN');
    const { baseTrans, tradeHistory } = ApiConfig;
    const url = baseTrans + tradeHistory;
    const params = {
      skip: skip,
      limit: limit
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  availabe_staking: async () => {
    const token = sessionStorage.getItem('COINPOWER_AUTH_TOKEN');
    const { baseStacking, availabe_staking } = ApiConfig;
    const url = baseStacking + availabe_staking;
    const headers = {
      "Content-Type": "application/json",
      "Authorization": token,

    };
    return ApiCallGet(url, headers);
  },

  pending_staking_history: async () => {
    const token = sessionStorage.getItem('COINPOWER_AUTH_TOKEN');
    const { baseStacking, pending_staking_history } = ApiConfig;
    const url = baseStacking + pending_staking_history;
    const headers = {
      "Content-Type": "application/json",
      "Authorization": token,

    };
    return ApiCallGet(url, headers);
  },

  staking_history: async () => {
    const token = sessionStorage.getItem('COINPOWER_AUTH_TOKEN');
    const { baseStacking, staking_history } = ApiConfig;
    const url = baseStacking + staking_history;
    const headers = {
      "Content-Type": "application/json",
      "Authorization": token,

    };
    return ApiCallGet(url, headers);
  },

  place_staking: async (currency_id, currency_Amount, selected_day) => {
    const token = sessionStorage.getItem('COINPOWER_AUTH_TOKEN');
    const { baseStacking, place_staking } = ApiConfig;
    const url = baseStacking + place_staking;
    const params = { currency_id, currency_Amount, selected_day };
    const headers = {
      "Content-Type": "application/json",
      "Authorization": token,

    };
    return ApiCallPost(url, params, headers);
  },

  break_staking: async (transaction_id) => {
    const token = sessionStorage.getItem('COINPOWER_AUTH_TOKEN');
    const { baseStacking, break_staking } = ApiConfig;
    const url = baseStacking + break_staking;
    const params = { transaction_id };
    const headers = {
      "Content-Type": "application/json",
      "Authorization": token,

    };
    return ApiCallPost(url, params, headers);
  },


  cancelOrder: async (orderId) => {
    const token = sessionStorage.getItem('COINPOWER_AUTH_TOKEN');
    const { baseExchange, cancelOrder } = ApiConfig;
    const url = baseExchange + cancelOrder;
    const params = {
      "order_id": orderId
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  getCode2: async (signid) => {
    const { baseAuth, getOtp } = ApiConfig;
    const url = baseAuth + getOtp;
    const params = {
      signId: signid,
      type: "forgot",
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  forgotPassword: async (signId, otp, password, cPassword) => {
    const { baseAuth, forgotpassword } = ApiConfig;
    const url = baseAuth + forgotpassword;
    const params = {
      email_or_phone: signId,
      verification_code: otp,
      new_password: password,
      confirm_password: cPassword,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  totalrefercount: async () => {
    const token = sessionStorage.getItem("COINPOWER_AUTH_TOKEN");
    const { baseAuth, totalrefercount } = ApiConfig;
    const url = baseAuth + totalrefercount;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };
    return ApiCallGet(url, headers);
  },

  comission_history: async (skip, limit) => {
    const token = sessionStorage.getItem("COINPOWER_AUTH_TOKEN");
    const { baseAuth, comission_history } = ApiConfig;
    const url = baseAuth + comission_history+`/${skip}/${limit}`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };
    return ApiCallGet(url, headers);
  },

  downline: async (userId,Level) => {
    const token = sessionStorage.getItem("COINPOWER_AUTH_TOKEN");
    const { baseAuth, downline } = ApiConfig;
    const url = baseAuth + downline;
    const params={
      userId:userId,
      level:Level,
    }
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };
    return ApiCallPost(url,params, headers);
  },

  
  changePassword: async (oldpassword, password, conPassword, email, otp) => {
    const token = sessionStorage.getItem("COINPOWER_AUTH_TOKEN");
    const { baseAuth, changePassword } = ApiConfig;
    const url = baseAuth + changePassword;
    const params = {
      old_password: oldpassword,
      new_password: password,
      confirm_password: conPassword,
      email: email,
      eotp: otp,
    };
    const headers = {
      "Content-Type": "application/json",
      'Authorization': token
    };
    return ApiCallPost(url, params, headers);
  },

  addSupportDetails: async (formData) => {
    const token = sessionStorage.getItem('WCCEX_AUTH_TOKEN');
    const { baseAdmin, addSupportDetails } = ApiConfig;
    const url = baseAdmin + addSupportDetails;
    const headers = {
      'Content-Type': 'multipart/form-data',
      "Authorization": token,
    };
    return ApiCallPost(url, formData, headers);
  },

  categoryList: async () => {
    const token = sessionStorage.getItem('COINPOWER_AUTH_TOKEN');
    const { baseAdmin, categoryList } = ApiConfig;
    const url = baseAdmin + categoryList;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },
  notificationList: async () => {
    const token = sessionStorage.getItem('COINPOWER_AUTH_TOKEN');
    const { baseAdmin, notificationList } = ApiConfig;
    const url = baseAdmin + notificationList;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },
  getPairs: async () => {
    const token = sessionStorage.getItem('COINPOWER_AUTH_TOKEN');
    const { baseCoin, getPairs } = ApiConfig;
    const url = baseCoin + getPairs;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },

  coinDetails: async (currency_id) => {
    if (!currency_id) {
      return
    }
    const token = sessionStorage.getItem('COINPOWER_AUTH_TOKEN');
    const { baseExchange, coinDetails } = ApiConfig;
    const url = baseExchange + coinDetails;
    const params = {
      "currency_id": currency_id
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  swapToken: async (base_currency, quote_currency, side, amount, swapped_amount) => {
    const token = sessionStorage.getItem('COINPOWER_AUTH_TOKEN');
    const { baseSwap, quick_buy_sell } = ApiConfig;
    const url = baseSwap + quick_buy_sell;
    const params = {
      base_currency, quote_currency, side, amount, swapped_amount
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  quickBuySellHistory: async (skip, limit) => {
    const token = sessionStorage.getItem('COINPOWER_AUTH_TOKEN');
    const { baseSwap, quickBuySellHistory } = ApiConfig;
    const url = baseSwap + quickBuySellHistory + `?skip=${skip}&limit=${limit}`;
    const headers = { 'Content-Type': 'application/json', "Authorization": token };
    return ApiCallGet(url, headers);
  },

  favoriteCoin: async (pairId) => {
    const token = sessionStorage.getItem('COINPOWER_AUTH_TOKEN');
    const { baseAuth, favoriteCoin } = ApiConfig;
    const url = baseAuth + favoriteCoin;
    const params = {
      "pair_id": pairId
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);

  },
  
  favoriteList: async () => {
    const token = sessionStorage.getItem('COINPOWER_AUTH_TOKEN');
    const { baseAuth, favoriteList } = ApiConfig;
    const url = baseAuth + favoriteList;

    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);

  },

  bannerList: async () => {
    const token = sessionStorage.getItem('COINPOWER_AUTH_TOKEN');
    const { baseAuth, bannerList } = ApiConfig;
    const url = baseAuth + bannerList;

    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);

  },

  setCurrency: async (currencyType) => {
    const token = sessionStorage.getItem("COINPOWER_AUTH_TOKEN");
    const { baseAuth, setCurrency } = ApiConfig;

    const url = baseAuth + setCurrency;
    const params = {
      currency: currencyType,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPut(url, params, headers);
  },

};

export default AuthService;
