import React, { useContext, useEffect, useRef, useState } from 'react'
import {  useNavigate, useParams } from 'react-router-dom';
import './trade-mobile.css'
import { ProfileContext } from '../../Context';
import { SocketContext } from '../../UtilityComponent/SocketContext';
import LoaderHelper from '../../UtilityComponent/Loading/LoaderHelper';
import AuthService from '../../Api_Module/Api_Services/AuthService';
import { alertSuccessMessage } from '../../UtilityComponent/CustomAlertMessage';
import TVChartContainer from '../../UtilityComponent/TVChartContainer';



const MobileChart = () => {
    let params = useParams()
    let URL = params?.pairs?.split('_');
    const { buySellSkip, handleUserDetials, kycStatus } = useContext(ProfileContext);
    const token = sessionStorage.getItem('CTEX_AUTH_TOKEN');
    const userId = sessionStorage.getItem('userId');
    const [urlPath, setUrlPath] = useState(URL ? URL : []);
    const [search, setsearch] = useState('');
    const [AllData, setAllData] = useState([]);
    const [BuyOrders, setBuyOrders] = useState([]);
    const [CoinPairDetails, setCoinPairDetails] = useState();
    const [RecentTrade, setRecentTrade] = useState([]);
    const [SellOrders, setSellOrders] = useState([]);
    const [SelectedCoin, setSelectedCoin] = useState();
    const [baseCurId, setbaseCurId] = useState();
    const [quoteCurId, setquoteCurId] = useState();
    const [buyprice, setbuyprice] = useState(0);
    const [buyamount, setbuyamount] = useState(1);
    const [sellPrice, setsellPrice] = useState(0);
    const [sellAmount, setsellAmount] = useState(1);
    const [infoPlaceOrder, setinfoPlaceOrder] = useState('LIMIT');
    const [coinFilter, setcoinFilter] = useState('ALL');
    const [BuyCoinBal, setBuyCoinBal] = useState();
    const [SellCoinBal, setSellCoinBal] = useState();
    const [orderType, setorderType] = useState('All');
    const [buyTriggerPrice, setbuyTriggerPrice] = useState();
    const [sellTrigegrPrice, setsellTrigegrPrice] = useState();
    const [priceDecimal, setpriceDecimal] = useState(8);
    const [pastOrders, setpastOrders] = useState([]);
    const [favCoins, setfavCoins] = useState([]);
    const [sellOrderPrice, setsellOrderPrice] = useState(undefined);
    const [buyOrderPrice, setbuyOrderPrice] = useState(undefined);
    const [tradeHistory, setTradeHistory] = useState([]);
    const [openOrders, setopenOrders] = useState([]);
    const [categoryList, setcategoryList] = useState([]);
    const [activeCategory, setActiveCategory] = useState("All");
    const [toggleChart, setToggleChart] = useState('chart');
    const [CoinDetails, setCoinDetails] = useState([]);
    const [tokenInfoLinks, settokenInfoLinks] = useState([]);
    const [loader, setloader] = useState(true);
    const [pairDetails, setPairDetails] = useState({ priceChange: 0, priceHigh: 0, priceLow: 0, volume: 0, });
    const [checkVisibleTable, setCheckVisibleTable] = useState({ OrderHistory: false, TrdeHistory: false });
    let socketId = sessionStorage.getItem("socketId")
    console.log("🚀 ~ MobileChart ~ socketId:", socketId)
    // ********* Socket Connection and Data fetch ********** //
    const { socket } = useContext(SocketContext);

    useEffect(() => {
        if (socket) {
            let payload = {
                'message': 'exchange',
                'socketId': socketId,
            }
            socket.emit('message', payload);
            socket.on('message', (data) => {
                setAllData(data);
                setBuyOrders(data?.buy_order);
                setRecentTrade(data?.recent_trades);
                setSellOrders(data?.sell_order?.reverse());
                setBuyCoinBal(data?.balance?.quote_currency_balance);
                setSellCoinBal(data?.balance?.base_currency_balance);
                setopenOrders(data?.open_orders?.reverse());
                setloader(false);
            });
        }
    }, [socket]);

    // ********* Auto Select Coin Pair after Socket Connection ********** //
    useEffect(() => {
        if (!SelectedCoin && CoinPairDetails) {
            var Pair;
            var filteredData;
            if (urlPath?.length > 0) {
                filteredData = CoinPairDetails?.filter?.((item) => {
                    return urlPath[0]?.includes(item?.base_currency) && urlPath[1]?.includes(item?.quote_currency)
                })
            }
            if (filteredData?.length > 0) {
                Pair = filteredData[0]
            }
            else {
                Pair = CoinPairDetails[0]
            }
            navigate(`/chart/${Pair?.base_currency}_${Pair?.quote_currency}`);
            setloader(true);
            setsellOrderPrice(undefined);
            setbuyOrderPrice(undefined);
            setSelectedCoin(Pair);
            setbaseCurId(Pair?.base_currency_id);
            setquoteCurId(Pair?.quote_currency_id);
            setbuyprice(Pair?.buy_price);
            setsellPrice(Pair?.sell_price);
            coinDetails(Pair?.base_currency_id);
            let payload = {
                'message': 'exchange',
                'userId': userId,
                'socketId': socketId,
                'base_currency_id': Pair?.base_currency_id,
                'quote_currency_id': Pair?.quote_currency_id,
            }
            socket.emit('message', payload);
        }
    }, [CoinPairDetails, infoPlaceOrder]);

    useEffect(() => {
        let interval;
        if (baseCurId && quoteCurId) {
            interval = setInterval(() => {
                let payload = {
                    'message': 'exchange',
                    'socketId': socketId,
                    'userId': userId,
                    'base_currency_id': baseCurId,
                    'quote_currency_id': quoteCurId,
                }
                socket.emit('message', payload);
            }, 1000)
        }
        return (() => {
            clearInterval(interval)
        })
    }, [baseCurId]);



    // ******** Check Decimals Of Buy Price ************//
    // function countDecimalPlaces(number) {
    //     const decimalString = number?.toString();
    //     const decimalIndex = decimalString?.indexOf('.');
    //     if (decimalIndex !== -1) {
    //         let length = decimalString?.length - decimalIndex - 1;
    //         if (length > 5) {
    //             setdecimals(length);
    //         } else {
    //             setdecimals(5)
    //         }
    //     } else {
    //         setdecimals(5)
    //     }
    // };


    // ********* Generate Dynamic Options for Price Decimals In order book ******** // 
    // const generateOptions = () => {
    //     const options = [];
    //     for (let i = decimals; i >= 1; i--) {
    //         const value = Math.pow(10, -i);
    //         options.push(
    //             <option selected={i === decimals} key={i} value={i}>
    //                 {value?.toFixed(i)}
    //             </option>
    //         );
    //     }
    //     return options;
    // };


    // ********* Filter Decimals By Sell price ********** //
    // useEffect(() => {
    //     countDecimalPlaces(sellPrice);
    // }, [sellPrice]);


    // ********* Update Buy Sell 24HChange High Low Volume Price********** //
    useEffect(() => {
        if (AllData && SelectedCoin) {
            let filteredData = AllData?.pairs?.filter((item) => {
                return item?.base_currency_id === SelectedCoin?.base_currency_id && item?.quote_currency_id === SelectedCoin?.quote_currency_id
            })
            if (filteredData) {
                setbuyprice(filteredData[0]?.buy_price);
                setsellPrice(filteredData[0]?.sell_price);
                setPairDetails({
                    priceChange: filteredData[0]?.change,
                    priceHigh: filteredData[0]?.high,
                    priceLow: filteredData[0]?.low,
                    volume: filteredData[0]?.volume,
                })
            }
        }
    }, [AllData]);


    // ********* Search Coins ********** //
    useEffect(() => {
        let filteredData = AllData?.pairs?.filter((item) => {
            return item?.base_currency?.toLowerCase()?.includes(search.toLowerCase()) || item?.quote_currency?.toLowerCase()?.includes(search?.toLowerCase())
        })
        setCoinPairDetails(filteredData)

    }, [search, AllData]);


    const functCheckRef = useRef(true);
    useEffect(() => {
        if (functCheckRef.current) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            CategoryList();
            coinDetails(SelectedCoin?.base_currency_id)
            token && favoriteList();
            functCheckRef.current = false;
        }
    }, []);


    const handleOrderPlace = async (infoPlaceOrder, buyprice, buyamount, base_currency_id, quote_currency_id, side) => {
        if (!buyprice || +buyprice === 0 || !buyamount || +buyamount === 0) {
            return
        }
        LoaderHelper.loaderStatus(true);
        await AuthService.placeOrder(infoPlaceOrder, buyprice, buyamount, base_currency_id, quote_currency_id, side).then((result) => {
            if (result?.success) {
                alertSuccessMessage('Order Placed Successfully!!')
                setbuyOrderPrice(undefined);
                setsellOrderPrice(undefined);
                setbuyamount(1);
                setsellAmount(1);
            }
        })
    };

    const skipModel = async (check) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.skipModel(check).then((result) => {
            if (result?.success) {
                alertSuccessMessage(result?.message);
                handleUserDetials();
            }
        })
    };

    const handlePastOrder = async (base_currency_id, quote_currency_id) => {
        setCheckVisibleTable({
            OrderHistory: true,
            TrdeHistory: false,
        });
        LoaderHelper.loaderStatus(true);
        await AuthService.pastOrder(base_currency_id, quote_currency_id).then((result) => {
            if (result?.success) {
                setpastOrders(result?.data)
            }
        })
    };

    const handleTradeHistory = async (base_currency_id, quote_currency_id) => {
        setCheckVisibleTable({
            OrderHistory: false,
            TrdeHistory: true,
        });
        LoaderHelper.loaderStatus(true);
        await AuthService.userTradeHistory(base_currency_id, quote_currency_id).then((result) => {
            if (result?.success) {
                setTradeHistory(result?.data);
            }
        })
    };

    const cancelOrder = async (orderId) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.cancelOrder(orderId).then((result) => {
            if (result?.success) {
                alertSuccessMessage('Order Cancelled Successfully');
            }
        })
    };

    const coinDetails = async (currId) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.coinDetails(currId).then((result) => {
            if (result?.success) {
                setCoinDetails(result?.data);
                settokenInfoLinks(result?.data?.links?.length > 0 ? JSON.parse(result?.data?.links) : [])
            }
        })
    };

    const handleAddFav = async (pairId) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.favoriteCoin(pairId).then((result) => {
            if (result?.success) {
                favoriteList()
            }
        })
    };

    const favoriteList = async () => {
        await AuthService.favoriteList().then((result) => {
            if (result?.success) {
                setfavCoins(result?.data?.pairs ? result?.data?.pairs : ['']);
            }
        });
    };

    const CategoryList = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.categoryList().then(async result => {
            if (result?.success) {
                setcategoryList(result?.data);
            }
        });
    };

    const navigate = useNavigate()
    const handleSelectCoin = (data) => {
        navigate(`/trade/${data?.base_currency}_${data?.quote_currency}`);
        setloader(true);
        setsellOrderPrice(undefined);
        setbuyOrderPrice(undefined);
        setSelectedCoin(data);
        setbaseCurId(data?.base_currency_id);
        setquoteCurId(data?.quote_currency_id);
        setbuyprice(data?.buy_price);
        setsellPrice(data?.sell_price);
        coinDetails(data?.base_currency_id);

        if (checkVisibleTable?.OrderHistory && token) {
            handlePastOrder(data?.base_currency_id, data?.quote_currency_id);
        } else if (checkVisibleTable?.TrdeHistory && token) {
            handleTradeHistory(data?.base_currency_id, data?.quote_currency_id);
        }

        let payload = {
            'message': 'exchange',
            'socketId': socketId,
            'userId': userId,
            'base_currency_id': data?.base_currency_id,
            'quote_currency_id': data?.quote_currency_id,
        }
        socket.emit('message', payload);


    };

    const handleOrderType = (e) => {
        setinfoPlaceOrder(e.target.value);
        if (e.target.value === 'MARKET') {
            setsellOrderPrice(undefined);
            setbuyOrderPrice(undefined);
            setbuyprice(SelectedCoin?.buy_price)
            setsellPrice(SelectedCoin?.sell_price)
        };
    };

    // ********* Filter Decimals By Sell price ********** //
    // useEffect(() => {
    //     countDecimalPlaces(sellPrice);
    // }, [sellPrice]);


    // ******** Check Decimals Of Buy Price ************//
    // function countDecimalPlaces(number) {
    //     let zeroes = countContinuousZerosAfterDecimal(number);
    //     setdecimals(zeroes)
    // };
    // function countContinuousZerosAfterDecimal(number) {
    //     const numStr = number.toString();

    //     let zeroCount = 0;
    //     let counting = false;

    //     for (let i = 0; i < numStr.length; i++) {
    //         const char = numStr[i];
    //         if (char === '.') {
    //             counting = true;
    //         } else if (counting && char === '0') {
    //             zeroCount++;
    //         } else if (counting && char !== '0') {
    //             break;
    //         }
    //     }
    //     console.log(zeroCount, 'zeroCount');
    //     if(zeroCount > 2){
    //         return 5;
    //     }else{
    //         return zeroCount + 4;
    //     }

    // }

    const HandleBuySellInput = (e) => {
        let decimals = 5;
        let regex = BUY_PRICE_REGEX();
        let regex2 = USER_PRICE_REGEX(decimals);
        let inputValue = e.target.value;
        switch (e.target.name) {
            case 'buyPricess':
                if (regex.test(inputValue) || inputValue === '') {
                    setbuyOrderPrice(inputValue);
                }
                break;
            case 'sellPricess':
                if (regex.test(inputValue) || inputValue === '') {
                    setsellOrderPrice(inputValue)
                }
                break;
            case 'buyAmount':
                if (regex2.test(inputValue) || inputValue === '') {
                    setbuyamount(e.target.value)
                }
                break;
            case 'sellAmount':
                if (regex2.test(inputValue) || inputValue === '') {
                    setsellAmount(e.target.value)
                }
                break;
            default:
                break;
        }
    };

    const BUY_PRICE_REGEX = () => {
        return new RegExp(/^(?!.*[-+*/])\d*(?:\.\d*)?$/);
    };

    const USER_PRICE_REGEX = (decimals) => {
        return new RegExp(`^(?!.*[-+*/])\\d*(?:\\.\\d{1,${decimals}})?$`);
    };


    return (
        <>
            <div className="trade-wrapper mobile_trade spot login_bg mb-5 pb-3">
                <div className="spot-container container-fluid p-0">

                    <div className="row g-2" >
                        <div className="col-12" >
                          


                            {/* Trade view */}
                            <div className="trade_card trade_chart mb-1 p-0"  >
                                {/* <div className="treade_card_header" > */}
                                    {/* <div className=" card_header_title  active" >
                                        K-Line
                                    </div> */}
                                    {/* <div className={`card_header_title ${toggleChart === 'tokenInfo' ? 'active' : ''}`} onClick={() => { setToggleChart('tokenInfo') }} >
                                        Token Info
                                    </div> */}
                                {/* </div> */}
                                <div className="cstm_tabs" >
                                    <div  >
                                        {SelectedCoin?.base_currency === undefined ? <div style={{ width: '100%', height: '500px' }}>
                                            <div className="loading-wave " style={{ width: '100%', height: '100%', alignItems: 'center' }}>
                                                <div className="loading-bar"></div>
                                                <div className="loading-bar"></div>
                                                <div className="loading-bar"></div>
                                                <div className="loading-bar"></div>
                                            </div>
                                        </div> :
                                            <TVChartContainer symbol={`${SelectedCoin?.base_currency}/${SelectedCoin?.quote_currency}`} />
                                        }
                                    </div>

                                </div>
                            </div>


                    

          
                </div>
           </div>
           </div>
           </div>
        </>
    )
}

export default MobileChart;
