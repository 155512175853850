import React, { useContext, useEffect, useState } from "react"
import LoaderHelper from "../../UtilityComponent/Loading/LoaderHelper";
import AuthService from "../../Api_Module/Api_Services/AuthService";
import { alertErrorMessage, alertSuccessMessage, alertWarningMessage } from "../../UtilityComponent/CustomAlertMessage";
import { ProfileContext } from "../../Context";
import { ApiConfig } from "../../Api_Module/Api_Config/ApiEndpoints";
import { CountryDetails } from "../../UtilityComponent/CountryList";
import { useOutletContext } from "react-router-dom";
const SettingsPage = () => {
  const [localImage, setlocalImage] = useState();
  const [profileImage, setprofileImage] = useState();
  const [resendOtp, setResendOtp] = useState(false);
  const [emailOtp, setEmailOtp] = useState('');
  const [mobileOtp, setmobileOtp] = useState('');
  const [setActiveTab] = useOutletContext()

  useEffect(() => {
    let URL = window.location.href?.split('/');
    let route = URL.pop();
    setActiveTab(route)
  }, []);

  // ********* Context ********** //
  const { firstName, setFirstName, email, setEmail, lastName, setLastName, setMobile, mobile, userImage, handleUserDetials, checkSignId, countryCode, setCountryCode } = useContext(ProfileContext);

  // ********* Update User Details ********** //
  const updateUserDetails = async (countryCode, myfile, firstName, lastName, emailId, mobile, otp) => {
    if (!emailId && !mobile) {
      alertErrorMessage('Network Error... Please try again later')
      return
    }
    var formData = new FormData();
    formData.append("profilepicture", myfile);
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("emailId", emailId);
    formData.append("mobileNumber", mobile);
    formData.append("cid", countryCode);
    formData.append(!checkSignId?.email ? 'eotp' : 'motp', otp);
    LoaderHelper.loaderStatus(true);
    await AuthService.updateSettings(formData).then(async (result) => {
      if (result?.success) {
        LoaderHelper.loaderStatus(false);
        alertSuccessMessage(result?.message);
        handleUserDetials();
        window.scrollTo({ top: 100, behavior: 'smooth' });
      }
    });
  };


  // ********* Handle Image Change ********** //
  const handleImageChange = (e) => {
    const fileUploaded = e.target.files[0];
    if (fileUploaded) {
      setlocalImage(URL.createObjectURL(fileUploaded));
      setprofileImage(fileUploaded)
    } else {
      setlocalImage();
      setprofileImage()
    }

  };

  // ********* Scroll Upside ********** //
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const handleGetOtp = async (signId, type) => {
    if (type === 'phone') {
      const [code, label] = countryCode.split(':');
      let filteredCountry = CountryDetails?.filter((data) => data?.phone === code && data?.label === label)?.map((item) => item?.phoneLength);
      if (signId?.length !== filteredCountry[0]) {
        alertWarningMessage(`Please enter valid phone number for ${label}`)
        return
      }
    };
    LoaderHelper.loaderStatus(true);
    await AuthService.getOtp(signId, 'registration').then(async (result) => {
      LoaderHelper.loaderStatus(false);
      if (result?.success) {
        alertSuccessMessage(result?.message);
        setResendOtp(true);
      }
    });
  };

  return (
    <>
      <div className="tab-pane" id="SecurityPill" role="tabpanel" aria-labelledby="Security-pill">
        <div className="row">
          <div className="col-md-12 col-lg-8 m-auto">
            <div className="form-field-wrapper switch_btn  border-dashed border-gray-300 bg-lighten card-rounded p-4">

              <div className="row">
                <div className="col-md-12">
                  <div className="avatar-upload">
                    <div className="avatar-edit">
                      <input type="file" id="imageUpload" name="myfile" onChange={handleImageChange} />
                      <label htmlFor="imageUpload">
                        <i className="ri-camera-line"></i>
                      </label>
                    </div>
                    <div className="avatar-preview">
                      <img alt="user" src={!userImage && !localImage ? "/images/profilelogo.png" : userImage && !localImage ? `${ApiConfig.baseUrl + userImage}` : localImage} />
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-4">
                  <div className="field-box">
                    <label htmlFor="text" className="form-label">
                      First Name
                    </label>
                    <input type="text" name="firstName" placeholder="Enter Your First Name" value={firstName} onChange={(e) => { setFirstName(e.target.value) }} />
                  </div>
                </div>
                <div className="col-md-12 mb-4">
                  <div className="field-box">
                    <label htmlFor="text" className="form-label">
                      Last Name
                    </label>
                    <input type="text" name="lastName" placeholder="Enter Last Name" value={lastName} onChange={(e) => { setLastName(e.target.value) }} />
                  </div>
                </div>
                <div className="col-md-12 mb-4">
                  <label htmlFor="text" className="form-label">
                    Email
                  </label>
                  <div className="field-box field-otp-box">
                    <input type="email" name="Email" placeholder="Enter Email" disabled={checkSignId?.email} value={email} onChange={(e) => { setEmail(checkSignId?.email ? email : e.target.value) }} />
                    {!checkSignId?.email && <button type="button" className="btn btn-sm btn-gradient" onClick={() => handleGetOtp(email)}>
                      <span>{resendOtp ? 'Resend OTP' : 'GET OTP '}</span>
                    </button>}
                  </div>
                </div>
                {!checkSignId?.email &&
                  <div className="col-md-12 mb-4">
                    <div className="field-box">
                      <label htmlFor="text" className="form-label">
                        OTP
                      </label>
                      <input type="text" name="OTP" placeholder="Enter Email OTP" value={emailOtp} onChange={(e) => { setEmailOtp(e.target.value) }} />
                    </div>
                  </div>
                }
                <div className="col-md-12 mb-4">
                  <label htmlFor="text" className="form-label">
                    Mobile Number
                  </label>
                  <div className="field-box">
                    <select name="countryCode" disabled={checkSignId?.mobile} value={countryCode} onChange={(e) => setCountryCode(e.target.value)}>
                      <optgroup >
                        {CountryDetails?.map(value => {
                          return <option value={`${value?.phone}:${value?.label}`} key={`${value?.phone}:${value?.label}`}>
                            {`${value?.label}  (+${value?.phone})`}
                          </option>
                        })}
                      </optgroup>
                    </select>
                  </div>
                </div>
                <div className="col-md-12 mb-4">
                  <div className="field-box field-otp-box">
                    <input type="number" name="Mobile" placeholder="Enter Mobile Number" disabled={checkSignId?.mobile} value={mobile} onChange={(e) => { setMobile(checkSignId?.mobile ? mobile : e.target.value) }} onWheel={(e) => { e.target.blur() }} />
                    {!checkSignId?.mobile && <button type="button" className="btn btn-sm btn-gradient" onClick={() => handleGetOtp(mobile, 'phone')}>
                      <span>{resendOtp ? 'Resend OTP' : 'GET OTP '}</span>
                    </button>}
                  </div>
                </div>
                {!checkSignId?.mobile && <div className="col-md-12 mb-4">
                  <div className="field-box">
                    <label htmlFor="text" className="form-label">
                      OTP
                    </label>
                    <input type="text" name="OTP" placeholder="Enter Mobile OTP" value={mobileOtp} onChange={(e) => { setmobileOtp(e.target.value) }} />
                  </div>

                </div>}
                <div className="col-md-12 mb-4 mt-4">
                  <div className="field-box">
                    <button className="btn btn-gradient w-100 justify-content-center btn-medium" type="button"
                      onClick={() => updateUserDetails(countryCode, profileImage, firstName, lastName, email, mobile, !checkSignId?.email ? emailOtp : mobileOtp)} >
                      <span>Submit</span>
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsPage;
