
import React, { useContext, useEffect } from "react";
import { Link} from "react-router-dom";
import { ProfileContext } from "../../../Context";

const UserHeader = () => {

  const { tradeTheme, setTradeTheme } = useContext(ProfileContext)

  // ********* Update Theme********** //
  const handleTheme = () => {
    const body = document.getElementById("body");
    let Theme = localStorage.getItem('theme-color');
    setTradeTheme(!tradeTheme)
    if (Theme === 'theme-light') {
      localStorage.setItem('theme-color', "theme-dark")
      body.classList.add('theme-dark');
      body.classList.remove("theme-light");
    } else {
      localStorage.setItem('theme-color', "theme-light")
      body.classList.add('theme-light');
      body.classList.remove("theme-dark");
    }
  };

  const ShowMobileSidebar = () => {
    let button = document.getElementById('qwert');
    button.classList.add("active");
  }
  const HideMobileSidebar = () => {
    let button = document.getElementById('qwert');
    button.classList.remove("active");
  }



  // ******* Show/Hide Search Bar for Coins*******//

  const handleClick = (event) => {
    const button = document.getElementById('serachBar2')
    if (typeof (event.target.className) === 'string' && event.target.className.includes('serachBar')) {
      button.classList.add('show')
    } else {
      if (button) {
        button.classList.remove('show');
      }
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <>
      <header className="ib-header header-default header-fixed header--sticky fluid-header">
        <div className="container-fluid">
          <div className="header-inner d-flex align-items-center justify-content-between">
            <div className="header-left d-flex align-items-center">
              <div className="logo-wrapper">
                <Link to="/" className="logo logo-light">
                  <img src="/images/logo-white.png" alt="brand" />
                </Link>
                <Link to="/" className="logo logo-dark">
                  <img src="/images/logo-white.png" alt="brand" />
                </Link>
              </div>
              <div className="mainmenu-wrapper ms-md-5">
                <nav id="sideNav" className="mainmenu-nav d-none d-xl-block">
                  <ul className="mainmenu">
                    <li><Link to="/" >Home</Link></li>

                    <li className="custom_dropdown" >

                      <Link to="/comingsoon" >Buy Crypto <i className="ri-arrow-down-s-line ms-1"></i> </Link>

                      <ul className="submenu">
                        <li>
                          <Link to="/comingsoon" >
                            <div className="d-flex align-items-center" >
                              <i className="ri-bank-card-fill me-4"></i>
                              <div>
                                <span>Debit/Credit Card</span>
                                <small>Buy Crypto via Card</small>
                              </div>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="/comingsoon">
                            <div className="d-flex  align-items-center" >
                              <i className="ri-arrow-up-down-line me-4"></i>
                              <div>
                                <span>Quick Buy/Sell</span>
                                <small>Fast and secure digital asset transactions</small>
                              </div>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="/comingsoon">
                            <div className="d-flex  align-items-center" >
                              <i className="ri-group-fill me-4"></i>
                              <div>
                                <span>P2P Trading</span>
                                <small>Bank transfer and more options</small>
                              </div>
                            </div>
                          </Link>
                        </li>
                      </ul>

                    </li>
                    <li><Link to="/MarketPage" >Market </Link> </li>
                    <li><a href="/trade/Header" >Trade </a></li>
                    <li> <Link to="/FundPage" >  Wallet </Link></li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="header-right d-flex align-items-center">
              <ul className="header-right-inner ">
             
                <li className="wallet-button  ">
                  <Link to="/login" className="btn btn-light ">
                    <span>Log In</span>
                  </Link>
                  <Link to="/signup" className="btn btn-gradient btn-small">
                      <span>Sign Up</span>
                    </Link>
                  
                </li>
                <li className="setting-option mobile-menu-bar d-block d-xl-none ">
                  <button className="hamberger-button" type="button" onClick={ShowMobileSidebar}>
                    <i className="ri-menu-2-fill"></i>
                  </button>
                </li>
                <label className="theme-switcher-label d-flex active" htmlFor="theme-switcher"                >
                  <input type="checkbox" className="theme-switcher" id="theme-switcher" onClick={handleTheme} />
                  <div className="switch-handle">
                    <i className="ri-moon-line dark-text"></i>
                    <i className="ri-sun-line light-text"></i>
                  </div>
                </label>

              </ul>
            </div>
          </div>
        </div>
      </header>


      {/* <!-- Start mobile menu area --> */}
      <div className="popup-mobile-menu" id="qwert"   >
        <div className="inner">
          <div className="header-top">
            <div className="logo logo-custom-css">
              <Link className="logo-light" to="/">
                <img src="/images/logo-white.png" alt="nft-logo" />
              </Link>
              <Link className="logo-dark" to="/">
                <img src="/images/logo-white.png" alt="nft-logo" />
              </Link>
            </div>
            <div className="close-menu" onClick={HideMobileSidebar}>
              <button className="close-button" type="button" >
                <i className="ri-close-fill"></i>
              </button>
            </div>
          </div>
          <nav>
            <ul className="mainmenu  accordion" id="accordionExample" >
              <li><Link to="/" onClick={() => { HideMobileSidebar() }}> Home </Link> </li>

              <li className="mobile_dropwdown accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button className="accordion-button p-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                    Buy Crypto
                  </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse  " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <ul className="submenu">
                    <li>
                      <Link to="/comingsoon" onClick={() => { HideMobileSidebar() }}>
                        <span>Debit/Credit Card</span>
                      </Link>
                    </li>

                    <li>
                      <Link to="/comingsoon" onClick={() => { HideMobileSidebar() }}>
                        <span>Quick Buy/Sell</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/comingsoon" onClick={() => { HideMobileSidebar() }}>
                        <span>P2P Trading</span>
                      </Link>
                    </li>

                  </ul>
                </div>
              </li>

              <li><Link to="/MarketPage" onClick={() => { HideMobileSidebar() }}>  Market</Link></li>
              <li> <Link to="/trade/Header" >Trade </Link></li>
              <li ><Link to="/FundPage" onClick={() => { HideMobileSidebar() }} > Wallet</Link> </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default UserHeader;
