import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper";
import { alertSuccessMessage, alertWarningMessage } from "../../UtilityComponent/CustomAlertMessage";
import AuthService from "../../Api_Module/Api_Services/AuthService";
import LoaderHelper from "../../UtilityComponent/Loading/LoaderHelper";
import ReCAPTCHA from "react-google-recaptcha";

const LoginPage = () => {
  const navigate = useNavigate();
  const [isAuthEnabled, setIsAuthEnabled] = useState(false);
  const [verification_code, setverification_code] = useState('');
  const [userDetails, setUserDetails] = useState();
  const [authType, setAuthType] = useState();
  const [signId, setSignId] = useState('');
  const [mobileNumber, setmobileNumber] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const recaptchaRef = React.createRef();
  const recaptchaRef2 = React.createRef();

  // ********* Login Function ********** //
  const handleLogin = async (signId, password, captchaCode) => {
    if (!signId || !password) {
      alertWarningMessage('Please enter Login Id and Password')
      return
    }
    LoaderHelper.loaderStatus(true);
    await AuthService.login(signId, password).then(async (result) => {
      if (result?.success) {
        if (result?.data?.['2fa'] === 0) {
          alertSuccessMessage('Login Successfull !!');
          sessionStorage.setItem("COINPOWER_AUTH_TOKEN", result?.data?.token);
          sessionStorage.setItem("userId", result?.data?.userId);
          navigate("/trade/exchange");
          window.location.reload()
        } else {
          setAuthType(result?.data?.['2fa'])
          setIsAuthEnabled(true);
          setUserDetails(result?.data)
        }
      } else {
        if (result?.message === "Invalid captcha") {
          recaptchaRef.current.reset();
          recaptchaRef2.current.reset();
        }
      }
    });
  };

  // ********* Auth Verificatioon Function ********** //
  const handleAuthVerify = async (signId, authType, vCode) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getCode(signId, authType, vCode).then(async (result) => {
      if (result?.success) {
        sessionStorage.setItem("COINPOWER_AUTH_TOKEN", result?.data?.token);
        sessionStorage.setItem("userId", result?.data?.userId);
        alertSuccessMessage('Login Successfull!!');
        navigate("/trade/exchange");
        window.location.reload()
      }
    });
  };

  // ********* Reset Form Inputs ********** //
  const handleReset = () => {
    setSignId('');
    setmobileNumber('');
    setPassword('');
    setShowPassword(false);
  };

  // ********* Scroll Upside ********** //
  useEffect(() => {
    window.scrollTo({ top: 20, behavior: 'smooth' });
  }, []);


  // ********* Google Captcha ***************//
  const siteKey = process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY;
  const [captchaCode, setCaptchaCode] = useState('');
  function CapcthaVerify(value) {
    setCaptchaCode(value);
  };
  return (
    <>
      <section className="inner-page-banner pb-0  ">
        <section className="login-bg ">
          <div className="container">
            <div className="">
              <div className="row align-items-center justify-content-center">
                <div className="col-xl-7 col-lg-12 ">
                  <div className="poster_slider">
                    <h2 className="mb-5">
                      Welcome to <br />
                      <span className="text-gradient">COINPOWER</span>
                    </h2>
                    <Swiper className="market_slider  pb-11"
                      spaceBetween={10} loop={true} autoplay={{
                        delay: 2000
                      }}
                      pagination={{ dynamicBullets: true, }}
                      modules={[Autoplay, Pagination, Navigation]}
                      breakpoints={{
                        320: {
                          slidesPerView: 1,
                          spaceBetween: 10,
                        },
                        640: {
                          slidesPerView: 2,
                          spaceBetween: 10,
                        },
                        768: {
                          slidesPerView: 2,
                          spaceBetween: 10,
                        },
                        1024: {
                          slidesPerView: 2,
                          spaceBetween: 10,
                        },
                      }}>
                      <SwiperSlide>
                        <a href="#/" className="main_poster"><img src="/images/poster_1.png" alt="" /></a>
                      </SwiperSlide>
                      <SwiperSlide>
                        <a href="#/" className="main_poster"><img src="/images/poster_2.png" alt="" /></a>
                      </SwiperSlide>
                     <SwiperSlide>
                        <a href="#/" className="main_poster"><img src="/images/poster_1.png" alt="" /></a>
                      </SwiperSlide>
                      <SwiperSlide>
                        <a href="#/" className="main_poster"><img src="/images/poster_2.png" alt="" /></a>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-12 ">
                  <div className="signup-wrapper p-0">
                    <div className="signup-content signin-content">
                      <div className={`sign-in_tab ${isAuthEnabled && "d-none"}`}>
                        <h3 className="mb-2">Log In to COINPOWER</h3>
                        <div className="mb-3">
                          <ul className="nav custom-tabs my-1">
                            <li>
                              <a className="active" data-bs-toggle="tab" href="#login_Mobile" onClick={handleReset}
                              > Mobile </a>
                            </li>
                            <li>
                              <a data-bs-toggle="tab" href="#login_email" onClick={handleReset}>
                                Email
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="tab-content">

                          {/* *******Login Mobile********** */}

                          <div className="tab-pane show  px-0 container active" id="login_Mobile">
                            <form action="#">
                              <div className="row">
                                <div className="col-12">
                                  <div className="fleld-box">
                                    <label htmlFor="Code" className="form-label">
                                      Mobile
                                    </label>
                                  </div>
                                </div>
                                {/* <div className="col-12 mb-4">
                                  <div className="field-box ">
                                    <select name="countryCode" defaultValue="+91">
                                      <optgroup label="Other countries">
                                      {CountryDetails?.map(value => {
                                      return <option value={`${value?.phone}:${value?.label}`} key={`${value?.phone}:${value?.label}`}>
                                        {`${value?.label}  (+${value?.phone})`}
                                      </option>
                                    })}
                                      </optgroup>
                                    </select>
                                  </div>
                                </div> */}
                                <div className="col-12 mb-4">
                                  <div className="field-box">
                                    <input
                                      id="mobile"
                                      type="number"
                                      placeholder="Enter mobile number"
                                      value={mobileNumber}
                                      onChange={(e) => { setmobileNumber(e.target.value) }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12 mb-4">
                                  <div className="field-box">
                                    <label htmlFor="password" className="form-label">
                                      Password
                                    </label>
                                    <div className="field-otp-box">
                                      <input
                                        name="mpassword"
                                        placeholder="Enter Password"
                                        type={showPassword ? 'text' : "password"}
                                        value={password}
                                        onChange={(e) => { setPassword(e.target.value) }}
                                      />
                                      <a className="show_password opt_btn btn-sm" href="#/" onClick={() => { setShowPassword(!showPassword) }}>
                                        {showPassword ? <i className="ri-eye-off-line" /> : <i className="ri-eye-line" />}

                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12 mb-4">
                                  <div className="field-box">
                                    <Link to="/ForgotPassPage">
                                      Forgot Password?
                                    </Link>
                                  </div>
                                </div>
                                {/* <div className="col-md-12 mb-4 mt-4">
                                  <div className="field-box">
                                    <ReCAPTCHA theme='dark'
                                      ref={recaptchaRef}
                                      sitekey={siteKey}
                                      onChange={CapcthaVerify}
                                    />
                                  </div>
                                </div> */}
                                <div className="col-md-12 mb-4 ">
                                  <div className="field-box">
                                    <button
                                      className="btn btn-gradient w-100 justify-content-center btn-medium"
                                      type="button" onClick={() => { handleLogin(mobileNumber, password, captchaCode) }}>
                                      <span>Sign In</span>
                                    </button>
                                  </div>
                                </div>

                              </div>
                            </form>
                          </div>

                          {/* *******Login Email********** */}

                          <div className="tab-pane  px-0   container" id="login_email">
                            <form action="#">
                              <div className="row">
                                <div className="col-md-12 mb-4">
                                  <div className="field-box">
                                    <label htmlFor="email" className="form-label">
                                      Email
                                    </label>
                                    <input id="email" name="email" type="email" placeholder="Enter Email"
                                      value={signId} onChange={(e) => { setSignId(e.target.value) }} />
                                  </div>
                                </div>
                                <div className="col-md-12 mb-4">
                                  <div className="field-box">
                                    <label htmlFor="password" className="form-label"
                                    >
                                      Password
                                    </label>
                                    <div className="field-otp-box">
                                      <input name="password" placeholder="Enter Password" type={showPassword ? 'text' : "password"} value={password} onChange={(e) => { setPassword(e.target.value) }} />
                                      <a className="show_password opt_btn btn-sm" href="#/" onClick={() => { setShowPassword(!showPassword) }}>
                                        {showPassword ? <i className="ri-eye-off-line" /> : <i className="ri-eye-line" />}

                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12 mb-4">
                                  <div className="field-box">
                                    <Link to="/ForgotPassPage">
                                      Forgot Password?
                                    </Link>
                                  </div>
                                </div>
                                {/* <div className="col-md-12 mb-4 mt-4">
                                  <div className="field-box ">
                                    <ReCAPTCHA
                                      ref={recaptchaRef2}
                                      theme='dark'
                                      sitekey={siteKey}
                                      onChange={CapcthaVerify}
                                    />
                                  </div>
                                </div> */}
                                <div className="col-md-12 mb-4 ">
                                  <div className="field-box">
                                    <button className="btn btn-gradient w-100 justify-content-center btn-medium" type="button" onClick={() => { handleLogin(signId, password, captchaCode) }}>
                                      <span>Sign In</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                          <div className="row justify-content-center text-center">
                            <div className="col-lg-12">
                              Don't have an account?  <Link to="/signup" className="color-primary"> Sign Up   </Link>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={`otb_tab  ${!isAuthEnabled && "d-none"} `}>
                        <div className="mb-3">
                          <h3 className="mb-2 text-center">Two Factor Verification</h3>
                          <p className="medium text-center">
                            {authType === 2
                              ? "Your Code will be sent to Google Authenticator App"
                              : authType === 1 ? `Your Code will be sent ${userDetails?.emailId}` : authType === 3 ? `Your Code will be sent ${userDetails?.mobileNumber}` : null}
                          </p>
                        </div>
                        <form>
                          <div className="row">
                            <div className="col-md-12 mb-4">
                              <div className="field-box">
                                <label htmlFor="Code" className="form-label">
                                  Enter Code
                                </label>
                                <input
                                  placeholder="Enter Verification Code"
                                  id="Verification"
                                  type="text"
                                  value={verification_code}
                                  onChange={(e) => { setverification_code(e.target.value) }}
                                />
                              </div>
                            </div>
                            <div className="col-md-12 mb-4">
                              <div className="field-box">
                              </div>
                            </div>
                            <div className="col-md-12 mb-4 mt-4">
                              <div className="field-box">
                                <button className="btn btn-gradient w-100 justify-content-center btn-medium" type="button"
                                  onClick={() => handleAuthVerify(mobileNumber ? mobileNumber : signId, authType, verification_code)}
                                >
                                  <span>Verify</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                        <div className="row justify-content-center text-center">
                          <div className="col-lg-12">
                            Back to{"  "}
                            <Link to="#" className={"color-primary"} onClick={() => setIsAuthEnabled(false)}>
                              Sign In
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div >
        </section >
      </section >
    </>
  );
};

export default LoginPage;
